<template>
  <div class="tournament-builder">
    <h2 class="text-xs-center">Tournament Builder</h2>

    <v-btn
      color="green"
      depressed
      dark
      :loading="adding"
      @click="onAddTournament"
      >Add tournament</v-btn
    >

    <TournamentBuilderTournament
      v-for="tournament in tournaments"
      :key="tournament.id"
      :tournament="tournament"
      :games="gamesArray"
      :saved="isSaved(tournament)"
      @input="onTournamentInput"
      @delete="onDeleteTournament"
      @increment="onTournamentRoundIncrement"
    />
  </div>
</template>

<script>
import Vue from "vue"

import { fetchGamesByRunStatus, fetchGames } from "@/services/game.service"
import { TournamentService } from "@/services/tournament/tournament.service"

import { ACTION_TYPES } from "@/store/TournamentModule"
import { Event } from "./event"
import {
  approximateTournament,
  DEFAULT_NUMBER_OF_TEAMS_PER_GAME
} from "@/helpers/tournament"
import { Tournament } from "./entities/Tournament"
import { TournamentGame } from "./entities/TournamentGame"
import { TournamentGameStatus } from "./entities/TournamentGameStatus"

import TournamentBuilderTournament from "./TournamentBuilderTournament.vue"

import { Game, Firebase } from "@/helpers"

export default Vue.extend({
  name: "TournamentBuilder",
  components: {
    TournamentBuilderTournament
  },
  props: {
    clientID: {
      type: String,
      required: true
    },
    orgID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      /** @type LocalTournament[] */
      tournaments: [],
      games: null,
      /** @type {string|undefined} */
      adding: false,
      saving: false
    }
  },
  computed: {
    originalTournaments() {
      return this.$store.state.tournament.tournaments
    },
    gamesArray() {
      return this.games
        ? Firebase.normalizeSnapshotToArray(this.games).sort((a, b) => {
            ;(parseInt(a.version) || 0) - (parseInt(b.version) || 0)
          })
        : []
    }
  },
  watch: {
    originalTournaments: {
      handler(value) {
        if (value) {
          this.tournaments = this.normalizeFireabseTournaments(value)
        }
      },
      immediate: true
    }
  },
  created() {
    this.$bus.$on(Event.TOURNAMENTS_UPDATE, this.getTournaments)
  },
  beforeDestory() {
    this.$bus.$off(Event.TOURNAMENTS_UPDATE, this.getTournaments)
  },
  async mounted() {
    const { orgID } = this
    this.getTournaments()
    const masters = await fetchGamesByRunStatus({
      orgID,
      value: Game.GAME_RUN_STATUS.MASTERS
    })
    const templates = await fetchGamesByRunStatus({
      orgID,
      value: Game.GAME_RUN_STATUS.USER_TEMPLATES
    })
    this.games = { ...masters, ...templates }
  },
  methods: {
    getTournaments() {
      const { clientID } = this
      this.$store.dispatch(`tournament/${ACTION_TYPES.GET_TOURNAMENTS}`, {
        clientID
      })
    },
    /**
     * @param {Tournament} tournament
     */
    isSaved(tournament) {
      return tournament.id in this.originalTournaments
    },
    async onAddTournament() {
      if (this.gamesArray[0]) {
        this.adding = true

        const id = await TournamentService.getNewID()

        const games = [
          new TournamentGame({
            id: this.getFirstMasterGameID(),
            status: TournamentGameStatus.UPCOMING
          })
        ]

        const tournament = new Tournament({ id, games })

        this.tournaments.push(tournament)

        this.adding = false

        this.$nextTick(() => {
          this.$bus.$emit(Event.CREATE_TOURNAMENT, tournament.id)
        })
      } else {
        console.error(
          '[TournamentBuilder]: unable to create a tournament due to a lack of "Master" games'
        )
      }
    },
    /**
     * @param {Tournament} tournament
     */
    async onDeleteTournament(tournament) {
      if (
        confirm(`Are you sure you want yo delete tournament ${tournament.id}`)
      ) {
        if (this.isSaved(tournament)) {
          await this.$store.dispatch(
            `tournament/${ACTION_TYPES.DELETE_TOURNAMENT}`,
            {
              orgID: this.orgID,
              clientID: this.clientID,
              tournamentID: tournament.id
            }
          )
          console.log("Event.TOURNAMENTS_UPDATE", Event.TOURNAMENTS_UPDATE)
          this.$bus.$emit(Event.TOURNAMENTS_UPDATE, null)
        } else {
          // We just delete it locally, since it'no saved in Firebase yet
          this.tournaments = this.tournaments.filter(
            t => t.id !== tournament.id
          )
        }
      }
    },
    /**
     * @param {Tournament} tournament
     */
    async onTournamentRoundIncrement({
      tournamentID,
      timestamp,
      roundID,
      round,
      label,
      teamsPerGame
    }) {
      this.saving = true

      const games = await fetchGames({
        clientID: this.clientID,
        orgID: this.orgID
      })

      const roundGameIDs = Object.keys(games || {}).filter(
        gameID => games && games[gameID].originalGameID === roundID
      )

      const n = roundGameIDs.length + 1

      await this.$store.dispatch("Games/copyFromOriginalGame", {
        orgID: this.orgID,
        clientID: this.clientID,
        originalGameID: roundID,
        name: `${label || `ROUND ${round}`} - Game ${n}`,
        round,
        assignable: round === 1 ? true : false,
        tournamentID,
        runStatus: "Tournament",
        gameType: "Standard",
        startAt: timestamp,
        hostID: this.$store.getters.user.id,
        deactivate: false,
        ondeck: false,
        teamsPerGame
      })

      this.saving = false

      this.getTournaments()
    },
    async onTournamentInput(tournament) {
      this.saving = true

      const { clientID } = this

      const { id: tournamentID } = tournament

      const originalTournament = this.getOiriginalTournament(tournament.id)
        ? this.normalizeOriginalTournament(
            tournament.id,
            this.getOiriginalTournament(tournament.id)
          )
        : null

      const getGamesDiffPairs = this.getGamesDiffPairs(
        originalTournament,
        tournament
      )

      for (const pair of getGamesDiffPairs) {
        if (pair.added === tournament.getSortedGames()[0].id) {
          const [firstGame] = tournament.getSortedGames()

          const {
            id: originalGameID,
            timestamp,
            label,
            expectedEndTime,
            teamsPerGame
          } = firstGame

          const games = tournament.getSortedGames()

          const [first] = approximateTournament(
            games.length,
            tournament.playersCount,
            tournament.playersPerTeam,
            games.map(({ teamsPerGame }) => teamsPerGame)
          )

          const { numberOfGames } = first

          await this.copyFromOriginalGame(
            originalGameID,
            numberOfGames,
            tournament,
            timestamp,
            label,
            expectedEndTime,
            teamsPerGame
          )
        }

        if (pair.deleted) {
          const count = await TournamentService.cleanUpTournamentGame(
            this.orgID,
            tournament.id,
            pair.deleted
          )

          if (pair.added) {
            const game = tournament.games.find(game => game.id === pair.added)

            await this.copyFromOriginalGame(
              pair.added,
              count,
              tournament,
              game.timestamp,
              game.label,
              game.expectedEndTime,
              game.teamsPerGame
            )
          }
        }
      }

      if (originalTournament) {
        for (const pair of this.getTimestampDiffPairs(
          originalTournament,
          tournament
        )) {
          console.log("pair", pair)
          console.log("pair.timestamp", pair.timestamp)
          await TournamentService.changeTimestamps(
            this.orgID,
            tournament.id,
            pair.id,
            pair.timestamp,
            pair.expectedEndTime
          )
        }
      }

      const normalizedTournament =
        this.normalizeTournamentToFirebase(tournament)

      console.log("[Tournamet Builder]: tournament that will be saved")
      console.dir(normalizedTournament)

      this.$store.dispatch(`tournament/${ACTION_TYPES.UPDATE_TOURNAMENT}`, {
        clientID,
        tournamentID,
        value: normalizedTournament
      })

      // await this.$store.dispatch(`tournament/${ACTION_TYPES.GET_TOURNAMENTS}`, {
      //   clientID
      // })

      this.$bus.$emit(Event.TOURNAMENTS_UPDATE, null)

      this.saving = false
    },
    /**
     * @param {Tournament} tournament
     */
    normalizeTournamentToFirebase(tournament) {
      const { ...rest } = tournament
      const object = tournament.getSortedGames().reduce(
        (prev, curr) => {
          prev.games[curr.id] = {
            ...curr,
            tournament: null
          }
          return prev
        },
        { ...rest, games: {} }
      )

      return object
    },
    /**
     * @param {?Tournament} t1
     * @param {Tournament} t2
     * @returns { { added?: string, deleted?: string }[] }
     */
    getGamesDiffPairs(t1, t2) {
      /** @type { { added?: string, deleted?: string }[] }  */
      const pairs = []
      const sortedGames2 = t2.getSortedGames()
      const entires2 = sortedGames2.entries()

      if (!t1) {
        /* eslint-disable no-unused-vars */
        for (const [_, game] of entires2) {
          pairs.push({ added: game.id })
        }
        /* eslint-enable no-unused-vars */
      } else {
        const sortedGames1 = t1.getSortedGames()
        const entires1 = sortedGames1.entries()

        for (const [i, game] of entires1) {
          if (!sortedGames2[i]) {
            pairs.push({ deleted: game.id })
          }
        }

        for (const [i, game] of entires2) {
          if (sortedGames1[i] && sortedGames1[i].id !== game.id) {
            pairs.push({ deleted: sortedGames1[i].id, added: game.id })
          } else if (!sortedGames1[i]) {
            pairs.push({ added: game.id })
          }
        }
      }
      return pairs
    },
    /**
     * @param {Tournament} t1
     * @param {Tournament} t2
     * @returns { { id: string, timestamp: number }[] }
     */
    getTimestampDiffPairs(t1, t2) {
      /** @type { { id: string, timestamp: number }[] }  */
      const pairs = []
      const t1Games = t1.getSortedGames()
      const t2Games = t2.getSortedGames()
      for (const [i, game] of t2Games.entries()) {
        if (!t1Games[i]) continue
        if (
          t1Games[i].timestamp !== game.timestamp ||
          t1Games[i].expectedEndTime !== game.expectedEndTime
        ) {
          pairs.push({
            id: game.id,
            timestamp: game.timestamp,
            expectedEndTime: game.expectedEndTime
          })
        }
      }
      return pairs
    },
    normalizeFireabseTournaments(tournaments) {
      //exclude id 0 - it's preGame statistic
      const pairs = Object.entries(tournaments).filter(([id]) => id != 0)

      return pairs.map(([id, tournament]) => {
        return this.normalizeOriginalTournament(id, tournament)
      })
    },
    /**
     * @returns {string}
     */
    getFirstMasterGameID() {
      if (this.gamesArray.length > 0) {
        return this.gamesArray[0].id
      }
      // TODO:
      throw new Error("")
    },
    /**
     * @param {string} tournamentID
     */
    getTournament(tournamentID) {
      return this.tournaments.find(tournament => tournament.id === tournamentID)
    },
    /**
     * @param {string} tournamentID
     */
    getOiriginalTournament(tournamentID) {
      return this.originalTournaments[tournamentID]
    },
    normalizeOriginalTournament(tournamentID, originalTournament) {
      const { games, ...rest } = originalTournament
      const normalizedGames = Object.entries(games).map(([id, game]) => {
        return new TournamentGame({ ...game, id })
      })

      return new Tournament({
        ...rest,
        games: normalizedGames,
        id: tournamentID
      })
    },
    copyFromOriginalGame(
      originalGameID,
      count,
      tournament,
      timestamp,
      label,
      expectedEndTime,
      teamsPerGame
    ) {
      return Promise.all(
        new Array(count).fill("").map((_, i) => {
          return this.$store.dispatch("Games/copyFromOriginalGame", {
            orgID: this.orgID,
            clientID: this.clientID,
            originalGameID,
            name: `${label || `ROUND 1`} - Game ${i + 1}`,
            round: 1,
            assignable: true,
            tournamentID: tournament.id,
            runStatus: "Tournament",
            gameType: "Standard",
            startAt: timestamp,
            expectedEndTime,
            hostID: this.$store.getters.user.id,
            teamsPerGame
          })
        })
      )
    }
  }
})
</script>

<style lang="scss">
.tournament-builder h2 {
  text-transform: uppercase;
}
</style>
