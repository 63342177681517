// TODO(Andrew): Factory, configurable property, types
const WithClientImagesMixin = {
  data() {
    return {
      _clientImages: new Array(6).fill("")
    }
  },
  computed: {
    // TODO(Andrew):
    clientImages() {
      return (
        this.localClient?.images ||
        this.object?.images ||
        this.$data._clientImages
      )
    }
  },
  watch: {
    "$data._clientImages"(value: string[]) {
      // TODO(Andrew):
      this.$set(this.localClient || this.object, "images", value)
    }
  }
}

export default WithClientImagesMixin
