






































import Vue from "vue"
import { RtbRow, RtbCol, RtbSelect, RtbButton } from "@/components/ui"
import Collection from "@shared/Collection"

import { db } from "@/firebase"

export const TemplateOption = {
  MASTER_TEMPLATE: "Master Template",
  USER_TEMPLATE: "User Template",
  CONTENT_TOOL_TEMPLATE: "Content Tool Template"
}

const TEMPLATE_TYPE_OPTIONS = Object.values(TemplateOption)

const N_OF_COPIES_OPTIONS = [1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50]

export default Vue.extend({
  name: "SessionSettingsTemplateSelectGroup",
  components: {
    RtbRow,
    RtbCol,
    RtbSelect,
    RtbButton
  },
  data() {
    return {
      limit: 500,
      templateId: null,
      templates: [],
      templateTypeOptions: TEMPLATE_TYPE_OPTIONS,
      templateType: TEMPLATE_TYPE_OPTIONS[0],
      nOfCopies: N_OF_COPIES_OPTIONS[0],
      nOfCopiesOptions: N_OF_COPIES_OPTIONS
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    ref: {
      async handler(newValue, oldValue) {
        if (newValue == null) return
        if (oldValue != null && newValue.isEqual(oldValue)) return
        this.templates = []
        this.templateId = null
        const snapshot = await newValue.once("value")
        this.onSnapshot(snapshot)
      },
      immediate: true
    }
  },
  methods: {
    add() {
      this.$emit("add", {
        n: this.nOfCopies,
        template: this.template
      })
    },
    onSnapshot(snapshot) {
      this.templates = Collection.normalize(snapshot.val())
    }
  },
  computed: {
    template() {
      return this.templates.find(template => template.id === this.templateId)
    },
    orgId() {
      return this.$store.getters.orgID
    },
    key() {
      if (this.templateType === TemplateOption.MASTER_TEMPLATE) return "Masters"
      if (this.templateType === TemplateOption.USER_TEMPLATE)
        return "User Templates"
      if (this.templateType === TemplateOption.CONTENT_TOOL_TEMPLATE)
        return "content-tool-room-template:published"
    },
    ref() {
      if (this.key == null) return null

      return db
        .ref(`org/${this.orgId}/games`)
        .orderByChild("runStatus")
        .equalTo(this.key)
        .limitToLast(this.limit)
    }
  }
})
