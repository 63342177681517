var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      ref: "dialog",
      attrs: { width: "290px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-flex",
                {
                  staticStyle: { "margin-right": "-12px" },
                  attrs: { "d-flex": "" },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _vm.isBeingEdited
                        ? _c(
                            "v-flex",
                            { attrs: { "d-flex": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "plus-minus-icon",
                                          attrs: { size: "17" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateTime(-_vm.offset)
                                            },
                                          },
                                        },
                                        [_vm._v(" remove_circle ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-flex", { attrs: { shrink: "" } }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "autowidth",
                                          rawName: "v-autowidth",
                                          value: {
                                            maxWidth: "100px",
                                            minWidth: "20px",
                                            comfortZone: 0,
                                          },
                                          expression:
                                            "{\n                  maxWidth: '100px',\n                  minWidth: '20px',\n                  comfortZone: 0\n                }",
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.offset,
                                          expression: "offset",
                                        },
                                      ],
                                      staticClass: "text-xs-center",
                                      staticStyle: { "line-height": "19px" },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.offset },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.offset = $event.target.value
                                        },
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "4px" },
                                      },
                                      [_vm._v("min")]
                                    ),
                                  ]),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "plus-minus-icon",
                                          attrs: { size: "17" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateTime(_vm.offset)
                                            },
                                          },
                                        },
                                        [_vm._v(" add_circle ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { "d-flex": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "tournament-date-container",
                              attrs: { "d-flex": "" },
                            },
                            [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  {
                                    staticClass: "tournament-date",
                                    attrs: {
                                      value: _vm.game.getTime(_vm.type),
                                      box: "",
                                      readonly: "",
                                      disabled: !_vm.isBeingEdited,
                                      "hide-details": "",
                                      "align-self-end": "",
                                    },
                                  },
                                  on
                                )
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "sr-only" }, [
            _vm._v("Game Date/Time fields"),
          ]),
          _c("v-date-picker", {
            attrs: { value: _vm.game.getDate(_vm.type), scrollable: "" },
            on: {
              input: function ($event) {
                return _vm.$emit("onSetDate", $event)
              },
            },
          }),
          _c(
            "v-sheet",
            { staticClass: "rtb-d-flex items-center" },
            [
              _c("v-select", {
                attrs: {
                  value: _vm.game.getHours(_vm.type),
                  items: _vm.Timer.hours,
                  label: "Hours",
                  "hide-details": "",
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("onSetHours", $event)
                  },
                },
              }),
              _c("span", { staticClass: "mx-2" }, [_vm._v(":")]),
              _c("v-select", {
                attrs: {
                  value: _vm.game.getMinutes(),
                  items: _vm.Timer.minutes,
                  label: "Minutes",
                  "hide-details": "",
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("onSetMinutes", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }