



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// @ts-nocheck
import Vue from "vue"
import mixins from "vue-typed-mixins"
import { mapGetters, mapActions } from "vuex"
import * as moment from "moment"
import _, { cloneDeep } from "lodash"
import shuffle from "array-shuffle"

import ClientSettingGame from "./ClientSettingGame"
import ClientSettingsGames from "./ClientSettingsGames"
import ClientSettingMatchEmails from "./ClientSettingMatchEmails"
import ClientSettingsAttendance from "./ClientSettingsAttendance"
import TournamentControls from "@/components/Tournament/TournamentControls"
import TournamentBuilder from "@/components/Tournament/TournamentBuilder"
import ClientSettingsStylesTabpanel from "./ClientSettingsStylesTabpanel.vue"
import ClientSettingActionsTabpanel from "@/components/Game/ClientSettingsActionsTabpanel.vue"
import ClientAudio from "@/components/GroupTeams/Common/ClientAudio"
import ClientGames from "@/components/Conversation/ClientGames.vue"
import SessionSettingsReferralPanel from "./SessionSettingsReferralPanel"

import { db } from "@/firebase"
import { v4 as uuidv4 } from "uuid"

import {
  CLIENT_GAME_ASSIGNMENT_TYPES,
  CLIENT_CUSTOM_INPUT_TYPES
} from "@/config"
import Team from "@shared/Team"
import LoginCustomInput from "@shared/enums/LoginCustomInput"

import { fetchGames } from "@/services/game.service"
import { GameType } from "@/entities/GameType"
import VueTimepicker from "vue2-timepicker"
import SvgIcon from "@/components/base/SvgIcon.vue"
import Packages from "@/entities/Package"
import PackagesEMD from "@/entities/PackageEMD"

import "vue2-timepicker/dist/VueTimepicker.css"

const EMAIL_MATCHING_COMPATIBLE_GAME_TYPES = {
  [GameType.Standard]: true,
  [GameType.GreenRoom]: true
}

import {
  RtbRow,
  RtbCol,
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbTextarea,
  RtbSelect,
  RtbCheckbox,
  RtbDatepicker,
  RtbTimepicker,
  RtbTabs,
  RtbTab,
  RtbSpinner,
  RtbInlineHelp,
  RtbContainer,
  RtbInputButton
} from "@/components/ui"
import ImageUploaderNext from "@/components/ImageUploader.vue"

import rules from "@/config/rules"

import WithClientImagesMixin from "@/mixins/client-images"

export const Event = {
  ADD_GAME: "CLIENT_SETTINGS:ADD_GAME"
}

export default mixins(Vue.extend(WithClientImagesMixin)).extend({
  name: "ClientSettings",
  components: {
    SessionSettingsReferralPanel,
    RtbRow,
    RtbCol,
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbInputButton,
    RtbCardActions,
    RtbTextInput,
    RtbTextarea,
    RtbSelect,
    RtbCheckbox,
    RtbDatepicker,
    RtbTimepicker,
    RtbTabs,
    RtbTab,
    RtbSpinner,
    RtbInlineHelp,
    RtbContainer,
    ClientSettingGame,
    ClientSettingMatchEmails,
    TournamentBuilder,
    TournamentControls,
    ClientSettingActionsTabpanel,
    ClientSettingsStylesTabpanel,
    ClientAudio,
    ClientSettingsAttendance,
    ClientSettingsGames,
    ImageUploaderNext,
    VueTimepicker,
    SvgIcon,
    ClientGames
  },
  props: {
    client: {
      type: Object,
      required: true
    },
    clientID: {
      type: String,
      reuired: true
    },
    copying: {
      type: Boolean,
      required: false
    },
    adding: {
      type: Boolean,
      required: false
    }
  },
  async mounted() {
    if (this.client.endTimestamp) {
      const date = new Date(this.client.endTimestamp)
      this.endDate = date
      this.endTime = {
        HH: date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`,
        mm:
          date.getMinutes() < 10
            ? `0${date.getMinutes()}`
            : `${date.getMinutes()}`
      }
    }
  },
  async created() {
    this.games = await fetchGames({
      orgID: this.orgID,
      clientID: this.clientID
    })
    this.object = {
      ...this.client,
      regularLogin: !!this.client.regularLogin
    }
    if (!this.object.numOfPlayers) this.object.numOfPlayers = 100
    if (!this.object.numOfTeams) this.object.numOfTeams = 3
  },
  data() {
    return {
      selected: {},
      working: false,
      games: null,
      customInputTypes: CLIENT_CUSTOM_INPUT_TYPES,
      tab: 0,
      loading: false,
      numOfPlayers: 200,
      object: null,
      restrictions: CLIENT_GAME_ASSIGNMENT_TYPES,
      numOfTeams: 3,
      currentRound: -1,
      startTime: { HH: "", mm: "" },
      startDate: null,
      endTime: { HH: "", mm: "" },
      endDate: null,
      canLoadClientTeam: false
    }
  },
  watch: {
    tab: {
      handler(index) {
        if (index === 0) this.fetchGame()
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("Games", ["copyGame", "updateGameAny"]),
    async unlink() {
      this.working = true
      const data = {}
      const rooms = this.sorted.map(({ id }) => id)
      for (const room of rooms) {
        data[`${room}/hostUserID`] = null
        data[`${room}/rooms`] = null
        data[`${room}/roomGroupId`] = null
      }
      await db.auxiliary().ref(`org/${this.orgID}/games`).update(data)
      await this.onGameUpdate()
      this.selected = {}
      this.working = false
    },
    async link() {
      this.working = true
      const data = {}
      const rooms = Object.entries(this.selected)
        .filter(([_, value]) => value === true)
        .map(([key]) => key)

      const room = this.sorted.find(
        room => room.hostUserID != null && rooms.includes(room.id)
      )

      const hostUserID = room?.hostUserID ?? null
      const roomGroupId = uuidv4()

      for (const room of rooms) {
        data[`${room}/rooms`] = rooms.filter(id => id !== room)
        data[`${room}/hostUserID`] = hostUserID
        data[`${room}/roomGroupId`] = roomGroupId
      }

      await db.auxiliary().ref(`org/${this.orgID}/games`).update(data)
      await this.onGameUpdate()
      this.selected = {}
      this.working = false
    },
    async makeTeamsPeristent(): Promise<void> {
      await this.$confirm({
        message:
          "This action will copy the first-round teams into the round 2+ games.",
        buttonColor: "danger"
      })

      const isStandard = room =>
        room.gameType === GameType.Standard || !room.gameType

      const rooms = this.gamesArray
        .filter(
          room =>
            !room.defaultKeynote &&
            !room.deletedTimestamp &&
            !room.endTimestamp &&
            isStandard(room) &&
            parseInt(room.round) > 0
        )
        .map(game => ({ ...game, round: parseInt(game.round) }))
        .sort((a, b) => a.round - b.round)

      const getTeamsByRoomId = async roomId => {
        const snapshot = await db
          .auxiliary()
          .ref(`org/${this.orgID}/game/${roomId}/teams`)
          .once("value")
        return Team.normalize(snapshot.val())
      }

      const roomsGroupedByRound = rooms.reduce((acc, val) => {
        if (acc[val.round]) acc[val.round].push(val)
        else acc[val.round] = [val]
        return acc
      }, {})

      const firstRoundRooms = roomsGroupedByRound[1] || []

      const firstRoundRoomsWithTeams = await Promise.all(
        firstRoundRooms.map(async room => {
          const teams = await getTeamsByRoomId(room.id)
          return { ...room, teams }
        })
      )

      const firstRoundTeams = firstRoundRoomsWithTeams.reduce(
        (acc, val) => [...acc, ...val.teams],
        []
      )

      const rounds = Object.keys(roomsGroupedByRound).sort((a, b) => a - b)

      const update = {}

      for (const round of rounds.slice(1)) {
        const rooms = roomsGroupedByRound[round]
        const n = Math.floor(firstRoundTeams.length / rooms.length)

        const pool = shuffle([...firstRoundTeams])

        for (const room of rooms) {
          const teams = {}

          for (let i = 0; i < n; i++) {
            const value = pool.shift()
            if (!value) continue
            teams[value.id] = {
              ...value,
              totalScore: 0
            }
          }

          update[`org/${this.orgID}/game/${room.id}/teams`] = teams
        }
      }

      await db.auxiliary().ref().update(update)

      await this.$info("Success!")
    },
    auditGames() {
      this.$emit("auditGames")
    },
    // TODO - replace with API reset endpoint
    async resetAllGames() {
      if (confirm("RESET ENTIRE ESCAPE ROOM")) {
        const games = this.filtered
        for (var i in games) {
          if (game?.autopilot) {
            await db
              .auxiliary()
              .ref(`/org/${this.orgID}/games/${game.theKey}/autopilot`)
              .set(null)
          }
          await db.auxiliary().ref(`org/${orgID}/game/${gameID}/play`).remove()
          this.$store.dispatch("updateGameStatusAny", { branchMissionID: null })
          // this.navigateTo(this.missionList[0] && this.missionList[0].id)
          const teamIDs = Object.keys(this.teams || {})

          teamIDs.forEach((teamID, i) => {
            this.$store.dispatch("updateTeam", {
              id: teamID,
              totalScore: 0,
              otherTeamFacts: [],
              submit: false,
              flippedState: "hidden"
            })
          })
          this.$store.dispatch("updateGameStatusAny", {
            flippedTeamID: null,
            revealCategoryAnswers: null,
            twoTruthsID: 0,
            royalRumbleID: null
          })
        }
      }
    },
    async fetchTeams(gameID) {
      const teams: Teams = await fetchGameTeamsObject({
        orgID: this.orgID,
        gameID
      })
      if (teams) {
        this.teams[gameID] = Object.entries(teams)
          .filter(([id, item]) => id && item.show)
          .map(([id, item]) => ({ ...item, id }))
      }
    },
    async fetchGame() {
      this.games = await fetchGames({
        orgID: this.orgID,
        clientID: this.clientID
      })
    },
    async renameGames() {
      if (
        confirm(
          "This will rename all the Standard room's external names to Round # Room #. You will need to navigate away from the Edit Event to see the changes. Are you sure?"
        )
      ) {
        var roundRooms
        for (var i = 1; i <= this.numOfRounds; i++) {
          roundRooms = this.filtered.filter(
            game =>
              game.round == i &&
              (game.gameType == GameType.Standard || game.gameType == null)
          )
          for (var j in roundRooms) {
            const roomNum = parseInt(j) + 1
            await this.updateGameAny({
              theKey: roundRooms[j].theKey,
              externalName: "Round " + i + " Room " + roomNum
            })
          }
        }
      }
    },
    async populateGamesOrigin() {
      if (
        confirm("You sure want to set round based origin to all games below?")
      ) {
        const ref = db.auxiliary().ref(`org/${this.orgID}/games`)

        const gamesGroupedByRound = this.filtered
          .filter(game => game.round)
          .reduce((acc, val) => {
            if (acc[val.round]) {
              acc[val.round].push(val)
            } else {
              acc[val.round] = [val]
            }
            return acc
          }, {})

        const update = Object.values(gamesGroupedByRound).reduce((acc, val) => {
          // non existing game ID
          const originalGameID = ref.push().key
          val.forEach(game => {
            acc[`${game.id}/originalGameID`] = originalGameID
          })
          return acc
        }, {})

        console.log("update", update)

        await ref.update(update)

        this.games = await fetchGames({
          orgID: this.orgID,
          clientID: this.clientID
        })
      } else {
        alert("Aborted.")
      }
    },
    async resetGamesOrigin() {
      if (confirm("You sure want to reset the origin of all games below?")) {
        const update = this.filtered.reduce((acc, val) => {
          acc[`${val.id}/originalGameID`] = null
          return acc
        }, {})
        console.log("UPDATING", update)
        await db.auxiliary().ref(`org/${this.orgID}/games`).update(update)

        this.games = await fetchGames({
          orgID: this.orgID,
          clientID: this.clientID
        })
      } else {
        alert("Aborted.")
      }
    },
    warningLocked() {
      alert(
        "Careful! This will allow everyone to come in or remove everyone from the entire expo."
      )
    },
    onStylesInput(styles) {
      this.$set(this.object, "styles", styles)
      // We reset `client.themeID` every time styles change
      this.$set(this.object, "themeID", null)
    },
    onThemeInput({ themeID }) {
      this.$set(this.object, "themeID", themeID)

      if (themeID === null) {
        this.update()
      }
    },
    async onGameUpdate() {
      this.games = await fetchGames({
        orgID: this.orgID,
        clientID: this.clientID
      })
    },
    async nextRound() {
      if (this.currentRound + 1 <= this.numOfRounds) {
        await this.stage()
        await this.unstage()
        await this.deactivate()
        this.currentRound++
      }
      await this.onGameUpdate()
    },
    async setStartTime() {
      const timestamp = moment(this.startTimestamp)
        .set({ seconds: 0 })
        .valueOf()
      console.log(timestamp)
      await Promise.all([
        this.filtered.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            startTimestamp: timestamp
          })
        })
      ])
      await this.onGameUpdate()
    },
    async setEndTime() {
      const obj = {}
      obj.endTimestamp = moment(this.endTimestamp).set({ seconds: 0 }).valueOf()
      obj.id = this.clientID
      const updatedClient = await this.$services
        .get("client")
        .then(service => service.updateClient(obj))

      this.object = { ...this.object, endTimestamp: obj.endTimestamp }

      if (updatedClient) {
        this.$emit("input", updatedClient)
      }
    },
    async changeMinutes(minutes) {
      await Promise.all([
        this.filtered.map(game => {
          const startTimestamp = game.startTimestamp + minutes * 60 * 1000
          return this.updateGameAny({
            theKey: game.theKey,
            startTimestamp
          })
        })
      ])
      await this.onGameUpdate()
    },
    async startNow() {
      const startTimestamp = Date.now() + 1000
      await Promise.all([
        this.filtered.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            startTimestamp
          })
        })
      ])
      await this.onGameUpdate()
    },
    async stage() {
      await Promise.all([
        this.filtered.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            ondeck: true
          })
        })
      ])
      await this.onGameUpdate()
    },
    async unstage() {
      await Promise.all([
        this.filtered.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            ondeck: false
          })
        })
      ])
      await this.onGameUpdate()
    },
    async activate() {
      await Promise.all([
        this.filtered.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            deactivate: false
          })
        })
      ])
      await this.onGameUpdate()
    },
    async deactivate() {
      await Promise.all([
        this.filtered.map(game => {
          return this.updateGameAny({
            theKey: game.theKey,
            deactivate: true
          })
        })
      ])
      await this.onGameUpdate()
    },
    async add({ template, count = 1 }) {
      this.working = true

      try {
        const startIn = Date.now() + 1000 * 60 * 30
        const srcSessionID = template.clientID || this.clientID
        const game = {
          ...template,
          gameType: template.gameType ?? GameType.Standard,
          clientID: this.clientID,
          sameClientID: true,
          ondeck: true,
          deactivate: true,
          defaultKeynote: false,
          runStatus: "Tournament"
        }

        for (let i = 0; i < count; i++) {
          await this.copyGame({ game, startIn, srcSessionID })
        }

        await this.onGameUpdate()
      } catch (e) {
        this.$info(e.message)
      }

      this.$info("Success!")

      this.working = false
    },
    getRules() {
      return rules
    },
    async update() {
      this.loading = true
      try {
        const object = cloneDeep(this.object)
        if (object.gameIDs) object.gameIDs = null
        if (!object.imageUrl) delete object.imageUrl
        if (object.regularLogin) object.password = null

        object.id = this.clientID
        const updatedClient = await this.$services
          .get("client")
          .then(service => service.updateClient(object))

        if (updatedClient) {
          this.$emit("input", updatedClient)
        }
      } finally {
        this.$emit("close")
      }
      this.loading = false
    },
    cancel() {
      this.$emit("close")
    },
    selectCSVFile() {
      this.$refs.csvFile.click()
    },
    _getOriginalGameIDByRound(round: number) {
      const games = this.gamesArray.filter(game => game.round == round)
      for (const game of games) {
        if (game.originalGameID !== undefined) return game.originalGameID
      }

      const ref = db.auxiliary().ref(`org/${this.orgID}/games`)

      return ref.push().key
    },
    updateGame(current, previous, difference) {
      if (current.round !== previous.round && !this.tournamentGames.length) {
        const originalGameID = this._getOriginalGameIDByRound(current.round)
        if (originalGameID !== current.originalGameID) {
          current.originalGameID = originalGameID
          this.updateGameAny({ theKey: current.theKey, originalGameID })
        }
      }

      this.$set(this.games, current.id, current)

      if (difference.defaultKeynote) {
        Promise.all(
          this.gamesArray
            .filter(game => game.defaultKeynote && game.theKey !== current.id)
            .map(({ theKey }) => {
              this.updateGameAny({ theKey, defaultKeynote: false })
              this.$set(this.games[theKey], "defaultKeynote", false)
            })
        )
      }
    },
    onTabChange(key) {
      if (key === "teams") {
        this.canLoadClientTeam = true
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isSuper", "token"]),
    ...mapGetters("pregame", ["tournaments"]),
    ...mapGetters(["orgID"]),
    unlinking() {
      return this.sorted.some(room => room.rooms != null)
    },
    linking() {
      return (
        Object.values(this.selected).filter(value => value == true).length > 1
      )
    },
    hasCustomInputOptions() {
      return (
        this.object?.customInputType === "select" ||
        this.object?.customInputType === LoginCustomInput.Location
      )
    },
    hasCustomInputLabelSetting() {
      return (
        this.object?.customInputType &&
        this.object?.customInputType !== LoginCustomInput.Location
      )
    },
    isLobby() {
      return Boolean(this.client?.tournament)
    },
    sessionUrl() {
      return `${window.location.origin}/login/${this.clientID}?auth=0`
    },
    sorted() {
      return _.chain(this.filtered)
        .sortBy(game => game.externalName || game.name || "")
        .sortBy("gameType")
        .sortBy(({ gameType: t }) => t === GameType.Standard || t == null)
        .sortBy(game => !game.defaultKeynote)
        .sortBy(game => game.round || 0)
        .sortBy(game => game.roomGroupId ?? null)
        .value()
    },
    gamesArray() {
      const array = []
      const games = this.games || {}
      for (let key in games) {
        const game = this.games[key]
        game.id = key
        array.push(game)
      }
      return array
    },
    tournament() {
      const [key] = Object.keys(this.tournaments || {}).filter(
        key => parseInt(key) !== 0
      )
      return key && this.tournaments ? this.tournaments[key] : null
    },
    tournamentGames() {
      return this.tournament
        ? Object.entries(this.tournament.games || {})
            .map(([id, game]) => ({ ...game, id }))
            .sort((a, b) => a.timestamp - b.timestamp)
        : []
    },
    gamesGroupedByRound() {
      return this.gamesArray
        .filter(item => !item.deletedTimestamp)
        .reduce((acc, val) => {
          if (val.round && acc[val.round]) {
            acc[val.round].push(val)
          } else if (val.round) {
            acc[val.round] = [val]
          }
          return acc
        }, {})
    },
    roundGamesCustom() {
      return Object.keys(this.gamesGroupedByRound)
        .map(key => parseInt(key))
        .sort()
    },
    numOfCustomRounds() {
      return this.roundGamesCustom[this.roundGamesCustom.length - 1] || 0
    },
    numOfRounds() {
      return Math.max(this.tournamentGames.length, this.numOfCustomRounds)
    },
    hasNextRound() {
      return this.currentRound - 1 < this.numOfRounds && this.numOfRounds
    },
    endTimestamp() {
      if (!this.endDate) return
      const date = new Date(this.endDate.getTime())
      date.setHours(this.endTime?.HH ?? 0)
      date.setMinutes(this.endTime?.mm ?? 0)
      return date.getTime()
    },
    startTimestamp() {
      if (!this.startDate) return
      const date = new Date(this.startDate.getTime())
      date.setHours(this.startTime?.HH ?? 0)
      date.setMinutes(this.startTime?.mm ?? 0)
      return date.getTime()
    },
    regularLogin: {
      get() {
        return !!this.object.regularLogin
      },
      set(value) {
        this.object.regularLogin = value
      }
    },
    isTournament() {
      return this.client.tournamentMode
    },
    filtered() {
      const index = this.currentRound - 1
      if (index > -1) {
        return this.gamesArray.filter(item => {
          if (!item) return false
          if (item.deletedTimestamp) return false
          if (item.tournamentID) {
            return (
              this.tournamentGames[index] &&
              item.originalGameID === this.tournamentGames[index].id
            )
          } else {
            return parseInt(item.round) === this.currentRound
          }
        })
      } else {
        return this.gamesArray.filter(item => !item.deletedTimestamp)
      }
    },
    gamesArrayForMatching() {
      return this.gamesArray.filter(
        item =>
          !item.deletedTimestamp &&
          (!item.gameType ||
            EMAIL_MATCHING_COMPATIBLE_GAME_TYPES[item.gameType])
      )
    },
    isValidEndTime() {
      return (
        this.endDate &&
        this.endTime.HH &&
        this.endTime.HH !== "HH" &&
        this.endTime.mm &&
        this.endTime.mm !== "mm"
      )
    },
    isValidStartTime() {
      return (
        this.startDate &&
        this.startTime.HH &&
        this.startTime.HH !== "HH" &&
        this.startTime.mm &&
        this.startTime.mm !== "mm"
      )
    },
    packageLevel: {
      get() {
        return (
          this.object.packageLevel === Packages.PREMIUM ||
          this.object.packageLevel === PackagesEMD.PREMIUM
        )
      },
      set(value) {
        if (value) {
          this.object.packageLevel = Packages.PREMIUM
        } else {
          this.object.packageLevel = Packages.STANDARD
        }
      }
    }
  }
})
