import { db } from "@/firebase"

import type { Client } from "@/types/client"

import type { Maybe } from "./firebase.service"
import { FirebaseService } from "./firebase.service"

export default class ClientService extends FirebaseService {
  static setLobby(session: Client, value: Boolean) {
    return db
      .auxiliary(session.id)
      .ref(`clients/${session.id}/tournament`)
      .set(value)
  }

  addClient(client: Client) {
    return db
      .ref("clients")
      .push(client)
      .then(({ key }) => {
        return this.getClientByID(key)
      })
  }

  getClientByID(id: string): Promise<Client | null> {
    return db
      .auxiliary(id)
      .ref(`clients/${id}`)
      .once("value")
      .then(snapshot => {
        const value: Client | null = snapshot.val()
        return value ? this.normalizeObjectLikeSnapshot(value, id) : value
      })
  }

  updateClient(client: Client) {
    const { id } = client
    const payload = this.deleteIdentity(client)

    return db
      .auxiliary(id)
      .ref(`clients/${id}`)
      .update(payload)
      .then(() => this.getClientByID(id))
  }

  deleteClient(id: string) {
    return db.auxiliary(id).ref(`clients/${id}`).remove()
  }

  getClients(
    orgID: string,
    options: { limit?: number } = {}
  ): Promise<Client[]> {
    let ref = db.ref("clients").orderByChild("orgID")

    if (options.limit) {
      ref = ref.equalTo(orgID).limitToLast(options.limit)
    } else {
      ref = ref.equalTo(orgID)
    }

    return ref.once("value").then(snapshot => {
      const value: Record<string, Client> | null = snapshot.val()

      if (value) {
        return this.normalizeSnapshotToArray(value)
      } else {
        return []
      }
    })
  }

  async getPassword(id: string): Promise<Maybe<string>> {
    return db
      .ref(`auth/client/${id}/password`)
      .once("value")
      .then(snapshot => snapshot.val() as Maybe<string>)
  }

  async setAuth(
    id: string,
    payload: { auth: boolean | null; password: string | null }
  ) {
    return db.ref(`auth/client/${id}`).update(payload)
  }
}
