import moment from "moment"
// eslint-disable-next-line no-unused-vars
import { Tournament } from "./Tournament"
import { DEFAULT_NUMBER_OF_TEAMS_PER_GAME } from "@/helpers/tournament"

export const START = "start"
export const END = "end"

export class TournamentGame {
  /**
   * @param { { id: string, status: string, timestamp?: number, teams?: Record<string, boolean> label?: string } } options
   */
  constructor(options) {
    this.id = options.id
    this.status = options.status
    this.timestamp = options.timestamp || Date.now()
    this.expectedEndTime =
      options.expectedEndTime || moment(this.timestamp).add("1", "h").valueOf()
    this.teams = options.teams || {}
    this.label = options.label || ""
    this.teamsPerGame = options.teamsPerGame || DEFAULT_NUMBER_OF_TEAMS_PER_GAME
    this.tournament = null
  }

  /**
   *
   * @param {Tournament} tournament
   */
  injectTournament(tournament) {
    this.tournament = tournament
  }

  isStarted() {
    return this.timestamp < Date.now()
  }

  getDate(type = START) {
    return this.getTimestamp(type).format("YYYY-MM-DD")
  }

  validateTimestamp(timestamp) {
    const nextRound = this.tournament.getNextGame(this.id)
    const nextRoundTmstp = nextRound ? nextRound.getTimestamp(START) : null

    if (nextRoundTmstp && nextRoundTmstp.valueOf() < timestamp) {
      const nextRoundDate = nextRoundTmstp.format("YYYY-MM-DD HH:mm")
      throw new Error(
        `Game Date can't be great then start timestamp of next round  ${nextRoundDate}`
      )
    }

    const prevRound = this.tournament.getPreviousGame(this.id)
    const prevRoundTmstp = prevRound ? prevRound.getTimestamp(END) : null
    if (prevRoundTmstp && prevRoundTmstp.valueOf() > timestamp) {
      const prevRoundDate = prevRoundTmstp.format("YYYY-MM-DD HH:mm")
      throw new Error(
        `Game Date can't be less then end timestamp of previous round ${prevRoundDate}`
      )
    }
  }

  /**
   *
   * @param timestamp
   * @param {string} type
   * @return {string}
   */
  setTimestamp(timestamp, type = START) {
    if (type === END) {
      this.expectedEndTime = timestamp.valueOf()
    } else {
      this.timestamp = timestamp.valueOf()
    }
  }

  /**
   *
   * @param {string} type
   * @return {string}
   */
  getTimestamp(type = START) {
    if (type === END) {
      return moment(this.expectedEndTime)
    } else {
      return moment(this.timestamp)
    }
  }

  /**
   *
   * @param {string} value - Date in "YYYY-MM-DD" format
   * @param {string} type = "start" or "end" timestamp
   */
  setDate(value, type = START) {
    const [year, month, date] = value.split("-")
    const timestamp = this.getTimestamp(type)
    timestamp.set({ year, month: month - 1, date }).valueOf()
    this.validateTimestamp(timestamp)
    this.setTimestamp(timestamp, type)
  }

  /**
   * @param {string} time - Time in "HH:mm" format
   * @param {string} type = "start" or "end" timestamp
   */
  setTime(time, type = START) {
    const [hours, minutes] = time.split(":")
    const timestamp = this.getTimestamp(type)
    timestamp.set({ hours, minutes }).valueOf()
    this.validateTimestamp(timestamp)
    this.setTimestamp(timestamp, type)
  }

  /**
   *
   * @param type
   */
  getTime(type = START) {
    return this.getTimestamp(type).format("HH:mm")
  }

  /**
   *
   * @param {string | number} hours
   * @param {string} type
   */
  setHours(hours, type = START) {
    const timestamp = this.getTimestamp(type)
    timestamp.set({ hours }).valueOf()
    this.validateTimestamp(timestamp)
    this.setTimestamp(timestamp, type)
  }

  getHours(type = START) {
    return this.getTimestamp(type).format("HH")
  }

  getMinutes(type = START) {
    return this.getTimestamp(type).format("mm")
  }

  /**
   *
   * @param {string | number} minutes
   * @param {string} type
   */
  setMinutes(minutes, type = START) {
    const timestamp = this.getTimestamp(type)
    timestamp.set({ minutes }).valueOf()
    this.validateTimestamp(timestamp)
    this.setTimestamp(timestamp, type)
  }

  clone() {
    return new TournamentGame({
      id: this.id,
      status: this.status,
      timestamp: this.timestamp,
      expectedEndTime: this.expectedEndTime,
      teams: this.teams,
      label: this.label,
      teamsPerGame: this.teamsPerGame
    })
  }
}
