var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "client-match-email" },
    [
      _c(
        "rtb-container",
        [
          _c(
            "rtb-card-actions",
            [
              _c("div", [
                _c("b", [_vm._v("Note:")]),
                _vm._v(
                  " The Simple Form of Login will be used if emails are exists"
                ),
                _c("br"),
              ]),
              _c("v-spacer"),
              _c("rtb-text-input", {
                attrs: { label: "Search" },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _c(
            "rtb-card-body",
            [
              _c("MatchEmailsTable", {
                attrs: {
                  games: _vm.games,
                  teams: _vm.teams,
                  data: _vm.data,
                  loading: _vm.loading,
                  search: _vm.search,
                },
              }),
              _c("input", {
                ref: "file",
                staticStyle: { display: "none" },
                attrs: { type: "file", id: "csv--file", accept: ".csv" },
                on: { change: _vm.onSelectCSVFile },
              }),
            ],
            1
          ),
          _c(
            "rtb-card-actions",
            [
              _c("v-spacer"),
              _c(
                "rtb-button",
                { attrs: { color: "danger" }, on: { click: _vm.onClear } },
                [
                  _c("svg-icon", { attrs: { name: "trash-regular" } }),
                  _vm._v(" Clear All "),
                ],
                1
              ),
              _c(
                "rtb-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showModal = true
                    },
                  },
                },
                [
                  _c("svg-icon", { attrs: { name: "plus-regular" } }),
                  _vm._v(" Add "),
                ],
                1
              ),
              _c(
                "rtb-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$refs.file.click()
                    },
                  },
                },
                [
                  _c("svg-icon", { attrs: { name: "cloud-download-regular" } }),
                  _vm._v(" Import From CSV "),
                ],
                1
              ),
              _c(
                "a",
                {
                  staticClass: "btn-link",
                  attrs: {
                    href: "/csv/match-players-template.csv",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "rtb-button",
                    [
                      _c("svg-icon", {
                        attrs: { name: "cloud-download-regular" },
                      }),
                      _vm._v(" CSV Template for Seth "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rtb-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onSave()
                    },
                  },
                },
                [
                  _vm.saving
                    ? _c("rtb-spinner", { attrs: { color: "currentColor" } })
                    : _c("svg-icon", { attrs: { name: "save-regular" } }),
                  _vm._v(" Save "),
                ],
                1
              ),
            ],
            1
          ),
          _c("MatchEmailsModal", {
            on: { addEmails: _vm.addEmails },
            model: {
              value: _vm.showModal,
              callback: function ($$v) {
                _vm.showModal = $$v
              },
              expression: "showModal",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }