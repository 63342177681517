<template>
  <rtb-card class="pl-2 pt-2 client-settings-actions-tab-panel">
    <rtb-row>
      <rtb-col xs="12">
        <h1>USE WITH CAUTION 💣</h1>
      </rtb-col>
    </rtb-row>
    <rtb-row>
      <rtb-col xs="12">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <rtb-button
              v-on="on"
              color="danger"
              :disabled="loading"
              @click="toggleLobby"
            >
              LOBBY {{ hasLobby ? "ON" : "OFF" }}
            </rtb-button>
          </template>
          <span>Toggle Lobby Page</span>
        </v-tooltip>
      </rtb-col>
      <rtb-col xs="12">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <rtb-button
              v-on="on"
              color="danger"
              :disabled="loading"
              @click="purge"
            >
              PURGE
            </rtb-button>
          </template>
          <span>Clear auto-assignment</span>
        </v-tooltip>
      </rtb-col>
    </rtb-row>
    <rtb-row>
      <rtb-col xs="12">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <rtb-button
              v-on="on"
              color="danger"
              :disabled="loading"
              @click="obliviate"
            >
              OBLIVIATE
            </rtb-button>
          </template>
          <span>Players will forget the games that they played</span>
        </v-tooltip>
      </rtb-col>
    </rtb-row>
    <rtb-row>
      <rtb-col xs="12">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <rtb-button
              v-on="on"
              color="danger"
              :disabled="loading"
              @click="clearChat('lobby')"
              class="btn"
            >
              CLEAR LOBBY CHAT
            </rtb-button>
          </template>
          <span>Clears all lobby chat messages</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <rtb-button
              v-on="on"
              color="danger"
              :disabled="loading"
              @click="clearChat('lobby-host')"
              class="btn"
            >
              CLEAR LOBBY-HOST CHAT
            </rtb-button>
          </template>
          <span>Clears all host chat messages in lobby</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <rtb-button
              v-on="on"
              color="danger"
              :disabled="loading"
              @click="clearChat('rooms')"
              class="btn"
            >
              CLEAR GAME CHAT
            </rtb-button>
          </template>
          <span>Clears all chat messages in all rooms</span>
        </v-tooltip>
      </rtb-col>
    </rtb-row>
    <rtb-row>
      <rtb-col xs="12">
        <rtb-button
          color="accent"
          :disabled="loading"
          @click="exportToJSON"
          class="btn"
        >
          Export to JSON
        </rtb-button>
        <rtb-button
          color="accent"
          :disabled="loading"
          @click="importFromJSON"
          class="btn"
        >
          Import from JSON
        </rtb-button>
      </rtb-col>
    </rtb-row>
    <rtb-row>
      <rtb-col xs="12">
        <rtb-spinner :style="{ opacity: loading ? '1' : '0' }" />
        <slot name="actions" :loading="loading"></slot>
      </rtb-col>
    </rtb-row>
  </rtb-card>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import { ActionTypes as ClientModuleActionTypes } from "@/store/ClientModule"
import {
  RtbButton,
  RtbRow,
  RtbCol,
  RtbCard,
  RtbCardActions,
  RtbSpinner
} from "@/components/ui"

import { fetchGamesByClientID } from "@/services/game.service"
import SessionService from "@/services/client"
import ApiService from "@/services/axios.service"
import File from "@/services/file.service"

export default Vue.extend({
  name: "ClientSettingsActionsTabpanel",
  props: {
    clientID: {
      type: String,
      required: true
    }
  },
  components: {
    RtbRow,
    RtbCol,
    RtbButton,
    RtbCard,
    RtbCardActions,
    RtbSpinner
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(["orgID"]),
    ...mapGetters({ gameID: "actualGameID" }),
    clientHasTheme() {
      return this.$store.state.auth.client.themeID !== undefined
    },
    hasLobby() {
      return Boolean(this.$store.state.auth.client?.tournament)
    }
  },
  methods: {
    toggleLobby() {
      SessionService.setLobby(this.$store.state.auth.client, !this.hasLobby)
    },
    copyClientTheme() {
      this.loading = true
      this.$store
        .dispatch(ClientModuleActionTypes.APPLY_CLINET_THEME_TO_GAMES, {
          games: this.$store.state.pregame.games
        })
        .finally(() => {
          this.loading = false
        })
    },
    async obliviate() {
      this.loading = true

      const { clientID, orgID } = this

      const snapshot = await db
        .auxiliary()
        .ref(`/org/${orgID}/games`)
        .orderByChild("clientID")
        .equalTo(clientID)
        .once("value")

      const update = Object.keys(snapshot.val() || {}).reduce((acc, val) => {
        acc[`${val}/access`] = null
        return acc
      }, {})

      await db.auxiliary().ref(`/org/${orgID}/games`).update(update)

      await db.auxiliary().ref(`client/${clientID}/history`).set(null)
      await db
        .auxiliary()
        .ref(`client/${clientID}/usersUnlockedGames`)
        .set(null)
      this.loading = false
    },
    async purge() {
      this.loading = true
      await db.auxiliary({
        [`client/${this.clientID}/usersPlayingGamesQueue`]: null,
        [`client/${this.clientID}/usersSpreadQueue`]: null,
        [`client/${this.clientID}/usersAutoChatQueue`]: null,
        [`client/${this.clientID}/playersToMap`]: null
      })
      this.loading = false
    },
    async clearChat(action = null) {
      if (!action || !["lobby", "lobby-host", "rooms"].includes(action)) return
      this.loading = true
      if (action === "lobby") {
        await db.auxiliary().ref(`client/${this.clientID}/messaging`).set(null)
      } else if (action === "lobby-host") {
        await db
          .auxiliary()
          .ref(`client/${this.clientID}/host_messaging`)
          .set(null)
      } else if (action === "rooms") {
        const games =
          (await fetchGamesByClientID(this.orgID, this.clientID)) || {}
        const updates = {}
        for (let gameID in games) {
          updates[`${gameID}`] = null
        }
        await db.auxiliary().ref(`org/${this.orgID}/messaging`).update(updates)
      }
      this.loading = false
    },
    async exportToJSON() {
      try {
        this.loading = true
        const data = await ApiService({
          method: "get",
          url: `session/${this.clientID}/export/json`,
          responseType: "blob"
        })
        const isJsonBlob = data =>
          data instanceof Blob && data.type === "application/json"
        if (isJsonBlob(data) === false) {
          console.error("Received data is not json blob", data)
          return
        }
        File.download(data, `Game_Data-${this.clientID}__${this.orgID}.json`)
      } finally {
        this.loading = false
      }
    },
    async importFromJSON() {
      const [file] = await File.select({
        multiple: false,
        accept: "application/json"
      })

      if (file !== undefined) {
        try {
          this.loading = true
          const { sessionID } = await ApiService({
            method: "post",
            url: `session/import/json`,
            data: file
          })
          const url = `${window.location.origin}/login/${sessionID}?auth=0`

          try {
            await this.$confirm({
              title: "Open new event?",
              message: `New Event with ID ${sessionID} was created. Do you want to open it?`
            })
            window.location.href = url
          } catch (e) {}
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
})
</script>
<style lang="scss">
.client-settings-actions-tab-panel {
  .rtb-row {
    margin: 0;
  }
  .btn {
    margin-right: 10px;
  }
}
</style>
