var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tournament-builder" },
    [
      _c("h2", { staticClass: "text-xs-center" }, [
        _vm._v("Tournament Builder"),
      ]),
      _c(
        "v-btn",
        {
          attrs: {
            color: "green",
            depressed: "",
            dark: "",
            loading: _vm.adding,
          },
          on: { click: _vm.onAddTournament },
        },
        [_vm._v("Add tournament")]
      ),
      _vm._l(_vm.tournaments, function (tournament) {
        return _c("TournamentBuilderTournament", {
          key: tournament.id,
          attrs: {
            tournament: tournament,
            games: _vm.gamesArray,
            saved: _vm.isSaved(tournament),
          },
          on: {
            input: _vm.onTournamentInput,
            delete: _vm.onDeleteTournament,
            increment: _vm.onTournamentRoundIncrement,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }