var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { "max-height": "80vh", overflow: "auto" },
      attrs: { column: "" },
    },
    [
      _c("DynamicScroller", {
        attrs: { items: _vm.gamesComputed, "min-item-size": 50 },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              var active = ref.active
              return [
                _c(
                  "DynamicScrollerItem",
                  {
                    attrs: {
                      item: item,
                      active: active,
                      "size-dependencies": [item.teams],
                      "data-index": index,
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("v-layout", { staticClass: "headline" }, [
                          _vm._v(" " + _vm._s(item.name) + " "),
                        ]),
                        _c("GameTeams", {
                          attrs: { game: item, playthroughs: _vm.playthroughs },
                          on: {
                            update: _vm.fetchPlayThroughs,
                            teamUpdate: _vm.onTeamUpdate,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }