var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    [
      _c(
        "rtb-card-body",
        [
          _c(
            "rtb-row",
            [
              _c(
                "rtb-col",
                { attrs: { xs: "2" } },
                [
                  _c("TagSelect", {
                    model: {
                      value: _vm.themesTag,
                      callback: function ($$v) {
                        _vm.themesTag = $$v
                      },
                      expression: "themesTag",
                    },
                  }),
                ],
                1
              ),
              _c(
                "rtb-col",
                { attrs: { xs: "7" } },
                [
                  _c("ThemesProvider", {
                    attrs: { tag: _vm.themesTag },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var themes = ref.themes
                          return [
                            _c("ThemeSelect", {
                              staticClass: "flex-grow",
                              attrs: { options: themes },
                              on: {
                                edit: _vm.showThemeCustomizerModal,
                                remove: _vm.deleteClientTheme,
                              },
                              model: {
                                value: _vm.themeID,
                                callback: function ($$v) {
                                  _vm.themeID = $$v
                                },
                                expression: "themeID",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("StylesEditor", {
            model: {
              value: _vm.styles,
              callback: function ($$v) {
                _vm.styles = $$v
              },
              expression: "styles",
            },
          }),
          _c(
            "rtb-row",
            [
              _c(
                "rtb-col",
                { attrs: { xs: "2" } },
                [
                  _c("rtb-text-input", {
                    attrs: { label: "Expo Custom Logo Style" },
                    model: {
                      value: _vm.localClient.customLogoStyle,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "customLogoStyle", $$v)
                      },
                      expression: "localClient.customLogoStyle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "rtb-col",
                {
                  staticClass: "mt-3",
                  staticStyle: { "align-self": "center" },
                  attrs: { xs: "4" },
                },
                [
                  _c("rtb-checkbox", {
                    staticClass: "mt-2",
                    attrs: {
                      label: "Use primary color for rooms game card background",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "help",
                        fn: function () {
                          return [
                            _c("rtb-inline-help", [
                              _vm._v(
                                "If checked, game cards with no header image will have the primary color as background "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.localClient.primaryColorAsGameCardBackground,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.localClient,
                          "primaryColorAsGameCardBackground",
                          $$v
                        )
                      },
                      expression:
                        "localClient.primaryColorAsGameCardBackground",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticStyle: { "margin-left": "-8px" }, attrs: { "mt-4": "" } },
            [
              _vm.dirty
                ? _c(
                    "v-btn",
                    { on: { click: _vm.save } },
                    [
                      _vm._v(" Save "),
                      _c("v-icon", { attrs: { right: "" } }, [_vm._v("save")]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "green", dark: "" },
                  on: { click: _vm.createTheme },
                },
                [
                  _vm._v(" Create "),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("add")]),
                ],
                1
              ),
              _vm.canReset
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "red", dark: "" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v(" Reset ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "red", dark: "" },
                  on: { click: _vm.purgeGames },
                },
                [
                  _vm._v(" Purge games "),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("refresh")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.theme
            ? _c("ThemePreview", { attrs: { theme: _vm.theme } })
            : _vm._e(),
          _vm.themeToCreate || _vm.theme
            ? _c("ThemeCustomizerModal", {
                attrs: { theme: _vm.themeToCreate || _vm.theme },
                on: { update: _vm.onThemeUpdate },
                model: {
                  value: _vm.isThemeCustomizerModalVisible,
                  callback: function ($$v) {
                    _vm.isThemeCustomizerModalVisible = $$v
                  },
                  expression: "isThemeCustomizerModalVisible",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }