<template>
  <v-dialog ref="dialog" width="290px">
    <template v-slot:activator="{ on }">
      <v-flex d-flex style="margin-right: -12px">
        <v-layout column>
          <v-flex d-flex v-if="isBeingEdited">
            <v-layout row>
              <v-flex d-flex align-center>
                <v-icon
                  size="17"
                  class="plus-minus-icon"
                  @click="updateTime(-offset)"
                >
                  remove_circle
                </v-icon>
              </v-flex>
              <v-flex shrink>
                <input
                  type="text"
                  v-autowidth="{
                    maxWidth: '100px',
                    minWidth: '20px',
                    comfortZone: 0
                  }"
                  v-model="offset"
                  class="text-xs-center"
                  style="line-height: 19px"
                /><span style="inline-block; margin-right: 4px;">min</span>
              </v-flex>
              <v-flex d-flex align-center>
                <v-icon
                  size="17"
                  class="plus-minus-icon"
                  @click="updateTime(offset)"
                >
                  add_circle
                </v-icon>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex d-flex style="position: relative">
            <v-flex d-flex class="tournament-date-container">
              <!-- <svg-icon name="calendar" class="rtb-color-white calendar" /> -->
              <v-text-field
                class="tournament-date"
                :value="game.getTime(type)"
                box
                readonly
                :disabled="!isBeingEdited"
                hide-details
                v-on="on"
                align-self-end
              />
            </v-flex>
          </v-flex>
        </v-layout>
      </v-flex>
    </template>
    <fieldset>
      <legend class="sr-only">Game Date/Time fields</legend>
      <v-date-picker
        :value="game.getDate(type)"
        scrollable
        @input="$emit('onSetDate', $event)"
      >
      </v-date-picker>
      <v-sheet class="rtb-d-flex items-center">
        <v-select
          :value="game.getHours(type)"
          :items="Timer.hours"
          label="Hours"
          hide-details
          @input="$emit('onSetHours', $event)"
        ></v-select>
        <span class="mx-2">:</span>
        <v-select
          :value="game.getMinutes()"
          :items="Timer.minutes"
          label="Minutes"
          hide-details
          @input="$emit('onSetMinutes', $event)"
        ></v-select>
      </v-sheet>
    </fieldset>
  </v-dialog>
</template>

<script>
import { START } from "./entities/TournamentGame"
import { Timer } from "@/helpers"

export default {
  name: "TournamentDateTimePicker",
  props: {
    isBeingEdited: {
      type: Boolean
    },
    game: {
      type: Object
    },
    type: {
      type: String,
      default: START
    }
  },
  methods: {
    updateTime(offset) {
      this.$emit("onSetOffset", offset)
    }
  },
  data: () => ({
    Timer,
    offset: 5
  })
}
</script>

<style scoped>
.plus-minus-icon {
  color: #fff !important;
}
</style>
