













































































































































































// TODO(Andrew): remove when time comes
// @ts-nocheck
import Vue, { PropType } from "vue"
import { mapActions } from "vuex"
import type { WithEvents } from "vue-typed-emit"
import { cloneDeep } from "lodash"
import { format } from "date-fns"
import { diff } from "deep-object-diff"
import uniqolor from "uniqolor"

import { GameType } from "@/entities/GameType"

import type { Game } from "@/types/game"

import rules from "@/config/rules"

const KEYNOTE_TYPES = [
  "YouTube",
  "Twitch",
  "Standard",
  "Our Video",
  "Scoreboard",
  "Image",
  GameType.GreenRoom
]

import {
  RtbRow,
  RtbCol,
  RtbTextInput,
  RtbSelect,
  RtbButton,
  RtbLink,
  RtbCheckbox,
  RtbInlineHelp
} from "@/components/ui"

export interface Events {
  update: [current: Game, previous: Game, diff: Partial<Game>]
}

export default (Vue as WithEvents<Events>).extend({
  name: "ClientSettingGame",
  components: {
    RtbRow,
    RtbCol,
    RtbTextInput,
    RtbButton,
    RtbSelect,
    RtbLink,
    RtbCheckbox,
    RtbInlineHelp
  },
  model: {
    prop: "selected",
    event: "input"
  },
  props: {
    game: {
      type: Object as PropType<Game>,
      required: true
    },
    index: Number,
    users: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localGame: null as Game | null,
      loading: false
    }
  },
  computed: {
    _selected: {
      get() {
        return this.selected
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    startTime(): string {
      return this.game?.startTimestamp
        ? format(new Date(this.game?.startTimestamp), "MM.dd.yyyy HH:mm")
        : ""
    },
    gameTypes() {
      return this.$store.getters["Games/gameTypes"]
    },
    gameID(): string {
      return this.game.theKey
    },
    userList() {
      return Object.values(this.users || {})
        .filter(user => typeof user === "object")
        .map(user => ({
          image: user.image,
          fullname: user.firstname + " " + user.lastname,
          id: user.id
        }))
    },
    keynoteDisabled() {
      return !KEYNOTE_TYPES.includes(this.localGame.gameType)
    },
    duration: {
      get() {
        return (this.localGame.duration ?? 0) / 60000
      },
      set(value: number) {
        this.$set(this.localGame, "duration", value * 60000)
      }
    },
    wrapperStyle() {
      if (this.localGame.roomGroupId != null)
        return { backgroundColor: uniqolor(this.localGame.roomGroupId).color }

      return this.index % 2 ? { backgroundColor: "#413d3c" } : {}
    }
  },
  watch: {
    game: {
      handler(newValue: Game) {
        this.localGame = cloneDeep(newValue)
      },
      immediate: true,
      deep: true
    },
    localGame: {
      handler() {
        this.save()
      },
      deep: true
    },
    "localGame.gameType"(type: string) {
      if (!KEYNOTE_TYPES.includes(type)) this.localGame.defaultKeynote = false
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    async save() {
      const difference = diff(this.game, this.localGame)

      if (Object.keys(difference).length > 0) {
        await this.updateGameAny({ theKey: this.game.theKey, ...difference })

        // TODO(Andrew): maybe fetch a game object
        this.$emit("update", this.localGame, this.game, difference)
      }
    },
    async maybeDelete() {
      if (confirm(`Delete game ${this.game.externalName || this.game.name}?`)) {
        await this.updateGameAny({
          theKey: this.game.theKey,
          deletedTimestamp: Date.now()
        })

        this.$emit("delete")
      }
    },
    async initializeToGame() {
      const { theKey: gameID, clientID } = this.game
      this.loading = true
      try {
        await this.$store.dispatch("auth/initializeToGame", {
          gameID,
          clientID
        })
        await this.$router.push(`/game/${clientID}${gameID}/settings`)
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    getRules() {
      return rules
    }
  }
})
