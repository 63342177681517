var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "no-click-animation": "", "max-width": "320" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "rtb-card",
        [
          _c(
            "rtb-container",
            [
              _c(
                "rtb-card-body",
                [
                  _c("rtb-textarea", {
                    attrs: { value: _vm.text, label: "Match Emails" },
                    on: { change: _vm.setAsList },
                  }),
                ],
                1
              ),
              _c(
                "rtb-card-actions",
                [
                  _c(
                    "rtb-button",
                    { attrs: { color: "dark" }, on: { click: _vm.closeModal } },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "rtb-button",
                    { on: { click: _vm.addEMails } },
                    [
                      _c("svg-icon", { attrs: { name: "save-regular" } }),
                      _vm._v(" Add "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }