var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.object
    ? _c(
        "rtb-tabs",
        {
          staticStyle: { height: "70lv" },
          attrs: { "tabs-class": "rtb-container" },
          on: { tabKey: _vm.onTabChange },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _vm.isLobby
            ? _c(
                "rtb-tab",
                { key: "games", attrs: { title: "Games" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: { centered: "" },
                              model: {
                                value: _vm.currentRound,
                                callback: function ($$v) {
                                  _vm.currentRound = $$v
                                },
                                expression: "currentRound",
                              },
                            },
                            [
                              _c(
                                "v-tab",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.currentRound = -1
                                    },
                                  },
                                },
                                [_vm._v(" All")]
                              ),
                              _vm._l(_vm.numOfRounds, function (i) {
                                return _c("v-tab", { key: "round-" + i }, [
                                  _vm._v(" Round " + _vm._s(i) + " "),
                                ])
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-card",
                    [
                      _c(
                        "rtb-card-body",
                        { staticStyle: { "padding-bottom": "0" } },
                        [
                          _c(
                            "rtb-container",
                            [
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "danger", size: "sm" },
                                  on: { click: _vm.resetGamesOrigin },
                                },
                                [_vm._v("Reset origin")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "danger", size: "sm" },
                                  on: { click: _vm.populateGamesOrigin },
                                },
                                [_vm._v("Populate Origin")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "danger", size: "sm" },
                                  on: { click: _vm.renameGames },
                                },
                                [_vm._v(" Rename Rooms ")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  attrs: { color: "danger", size: "sm" },
                                  on: { click: _vm.makeTeamsPeristent },
                                },
                                [_vm._v(" PERSISTENT TEAMS ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-card",
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-container",
                            [
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "sm" },
                                  on: { click: _vm.stage },
                                },
                                [_vm._v("Stage")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "sm" },
                                  on: { click: _vm.unstage },
                                },
                                [_vm._v(" Unstage ")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "sm" },
                                  on: { click: _vm.activate },
                                },
                                [_vm._v("On")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-4",
                                  attrs: { size: "sm" },
                                  on: { click: _vm.deactivate },
                                },
                                [_vm._v(" Off ")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeMinutes(-5)
                                    },
                                  },
                                },
                                [_vm._v(" - 5 ")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeMinutes(-1)
                                    },
                                  },
                                },
                                [_vm._v(" - 1 ")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "sm" },
                                  on: { click: _vm.startNow },
                                },
                                [_vm._v(" Start Now ")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeMinutes(1)
                                    },
                                  },
                                },
                                [_vm._v(" + 1 ")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mr-4",
                                  attrs: { size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeMinutes(5)
                                    },
                                  },
                                },
                                [_vm._v("+ 5")]
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "rtb-row",
                                    [
                                      _c("rtb-col", { attrs: { xs: "6" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "rtb-d-flex align-end",
                                          },
                                          [
                                            _c(
                                              "rtb-row",
                                              { staticClass: "flex-grow" },
                                              [
                                                _c(
                                                  "rtb-col",
                                                  { attrs: { xs: "6" } },
                                                  [
                                                    _c("rtb-datepicker", {
                                                      attrs: {
                                                        "drop-direction":
                                                          "down",
                                                        label: "Date",
                                                      },
                                                      model: {
                                                        value: _vm.startDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.startDate = $$v
                                                        },
                                                        expression: "startDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "rtb-col",
                                                  { attrs: { xs: "6" } },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        staticClass:
                                                          "time-picker-container",
                                                        attrs: { column: "" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "label",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(" Time "),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "vue-time-picker",
                                                            attrs: { row: "" },
                                                          },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  "drop-direction":
                                                                    "down",
                                                                  format:
                                                                    "HH:mm",
                                                                  "manual-input":
                                                                    "",
                                                                  "fixed-dropdown-button":
                                                                    "",
                                                                  "hide-clear-button":
                                                                    "",
                                                                  "close-on-complete":
                                                                    "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "dropdownButton",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "action",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "svg-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        name: "clock-light",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1662387832
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.startTime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.startTime =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "startTime",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-shrink-0 ml-3",
                                              },
                                              [
                                                _c(
                                                  "rtb-button",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.isValidStartTime,
                                                    },
                                                    on: {
                                                      click: _vm.setStartTime,
                                                    },
                                                  },
                                                  [_vm._v("Set Start")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("rtb-col", { attrs: { xs: "6" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "rtb-d-flex align-end",
                                          },
                                          [
                                            _c(
                                              "rtb-row",
                                              { staticClass: "flex-grow" },
                                              [
                                                _c(
                                                  "rtb-col",
                                                  { attrs: { xs: "6" } },
                                                  [
                                                    _c("rtb-datepicker", {
                                                      attrs: { label: "Date" },
                                                      model: {
                                                        value: _vm.endDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.endDate = $$v
                                                        },
                                                        expression: "endDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "rtb-col",
                                                  { attrs: { xs: "6" } },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        staticClass:
                                                          "time-picker-container",
                                                        attrs: { column: "" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "label",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(" Time "),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "vue-time-picker",
                                                            attrs: { row: "" },
                                                          },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  "drop-direction":
                                                                    "down",
                                                                  format:
                                                                    "HH:mm",
                                                                  "manual-input":
                                                                    "",
                                                                  "fixed-dropdown-button":
                                                                    "",
                                                                  "hide-clear-button":
                                                                    "",
                                                                  "close-on-complete":
                                                                    "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "dropdownButton",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "action",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "svg-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        name: "clock-light",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1662387832
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.endTime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.endTime =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "endTime",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-shrink-0 ml-3",
                                              },
                                              [
                                                _c(
                                                  "rtb-button",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.isValidEndTime,
                                                    },
                                                    on: {
                                                      click: _vm.setEndTime,
                                                    },
                                                  },
                                                  [_vm._v("Set End")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "rtb-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "12" } },
                                        [
                                          _vm.linking
                                            ? _c(
                                                "rtb-button",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    disabled: _vm.working,
                                                    size: "sm",
                                                  },
                                                  on: { click: _vm.link },
                                                },
                                                [_vm._v(" Link ")]
                                              )
                                            : _vm._e(),
                                          _vm.unlinking
                                            ? _c(
                                                "rtb-button",
                                                {
                                                  attrs: {
                                                    disabled: _vm.working,
                                                    size: "sm",
                                                  },
                                                  on: { click: _vm.unlink },
                                                },
                                                [_vm._v(" Unlink ")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ClientSettingsGames", {
                    attrs: { games: _vm.sorted, disabled: _vm.working },
                    on: {
                      add: _vm.add,
                      delete: _vm.onGameUpdate,
                      update: _vm.updateGame,
                    },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                  _c("div", { staticStyle: { height: "150px" } }),
                ],
                1
              )
            : _vm._e(),
          _vm.isLobby
            ? _c(
                "rtb-tab",
                { key: "tournament", attrs: { title: "Tournament" } },
                [
                  _c(
                    "rtb-card",
                    { staticStyle: { "background-color": "#fff" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c("TournamentBuilder", {
                            attrs: { clientID: _vm.clientID, orgID: _vm.orgID },
                          }),
                          _c("TournamentControls", { staticClass: "mt-3" }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "rtb-tab",
            { key: "general", attrs: { title: "General" } },
            [
              _c(
                "rtb-card",
                [
                  _c(
                    "rtb-container",
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Name" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The name that appears on the game login page"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      4260921042
                                    ),
                                    model: {
                                      value: _vm.object.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.object, "name", $$v)
                                      },
                                      expression: "object.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Description",
                                      rules: _vm.getRules().ClientDescription,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Internal notes for the game"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1284470735
                                    ),
                                    model: {
                                      value: _vm.object.description,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.object, "description", $$v)
                                      },
                                      expression: "object.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-select", {
                                    attrs: {
                                      options: _vm.restrictions,
                                      identity: "code",
                                      label: "Player Auto Assignment",
                                      "option-text": "text",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "This is the method used to assign players to the game when they arrive. FREE ROAM: They choose their own games. PLEDGED: They stay on the game and can NOT choose another one. STICKY: They can choose another one. TRAINING - FREE ROAM: They are assigned using PLEDGED only to the first game, and then can pick after that. TRAINING - STICKY: They are assigned using PLEDGED only to the first game and then it's STICKY thereafter"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      288427887
                                    ),
                                    model: {
                                      value: _vm.object.restriction,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.object, "restriction", $$v)
                                      },
                                      expression: "object.restriction",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Players capacity",
                                      rules: _vm.getRules().ClientMaxCapacity,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The maximum amount of online participants allowed into the game"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3984378051
                                    ),
                                    model: {
                                      value: _vm.object.maxCapacity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "maxCapacity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "object.maxCapacity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Redirect URL",
                                      rules: _vm.getRules().ClientRedirectURL,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The URL players are sent to when the game is full"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1441651358
                                    ),
                                    model: {
                                      value: _vm.object.redirectURL,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.object, "redirectURL", $$v)
                                      },
                                      expression: "object.redirectURL",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Return Url",
                                      rules: _vm.getRules().ClientReturnURL,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "A URL for participants to return to their previous meeting"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      4226873722
                                    ),
                                    model: {
                                      value: _vm.object.returnUrl,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.object, "returnUrl", $$v)
                                      },
                                      expression: "object.returnUrl",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Text for Return Url" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The text that appears for the Return URL"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      164888333
                                    ),
                                    model: {
                                      value: _vm.object.returnUrlText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "returnUrlText",
                                          $$v
                                        )
                                      },
                                      expression: "object.returnUrlText",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Later Comer YouTube Code",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "YouTube code for latecomer video"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      994637071
                                    ),
                                    model: {
                                      value: _vm.object.latecomerVideoCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "latecomerVideoCode",
                                          $$v
                                        )
                                      },
                                      expression: "object.latecomerVideoCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "End Early YouTube Code" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  " YouTube code for early end video "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3419200873
                                    ),
                                    model: {
                                      value: _vm.object.earlyEndVideo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "earlyEndVideo",
                                          $$v
                                        )
                                      },
                                      expression: "object.earlyEndVideo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Text Notification" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  " Announcement that shows below the live chat. Needs to be filled out for the Return Link to show "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1465239526
                                    ),
                                    model: {
                                      value: _vm.object.announcement,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "announcement",
                                          $$v
                                        )
                                      },
                                      expression: "object.announcement",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Lobby Name" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  ' Replace "Lobby" with something else '
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      791164132
                                    ),
                                    model: {
                                      value: _vm.object.lobbyName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.object, "lobbyName", $$v)
                                      },
                                      expression: "object.lobbyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      value: _vm.sessionUrl,
                                      clipboard: "",
                                      label: "URL",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The Game URL to share with participants"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2581472451
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Scribe Sound" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  " The sound effect when you "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2055295965
                                    ),
                                    model: {
                                      value: _vm.object.scribeSound,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.object, "scribeSound", $$v)
                                      },
                                      expression: "object.scribeSound",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Scribe Label" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  " New name for scribe throughout the event "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2179223851
                                    ),
                                    model: {
                                      value: _vm.object.scribeLabel,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.object, "scribeLabel", $$v)
                                      },
                                      expression: "object.scribeLabel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-datepicker", {
                                    attrs: {
                                      label: "Event Start Date",
                                      timestamp: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Does not effect room start times. What is shown upon login for countdown timer."
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3157036534
                                    ),
                                    model: {
                                      value: _vm.object.startTimestamp,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "startTimestamp",
                                          $$v
                                        )
                                      },
                                      expression: "object.startTimestamp",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-timepicker", {
                                    attrs: {
                                      label: "Event Start Time",
                                      timestamp: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Does not effect room start times. This is what is shown upon login for countdown timer."
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3897198250
                                    ),
                                    model: {
                                      value: _vm.object.startTimestamp,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "startTimestamp",
                                          $$v
                                        )
                                      },
                                      expression: "object.startTimestamp",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Locked" },
                                on: { click: _vm.warningLocked },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "This removes all players from the expo and does not let new players in"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3824962781
                                ),
                                model: {
                                  value: _vm.object.locked,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "locked", $$v)
                                  },
                                  expression: "object.locked",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Debug" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "This is for tech users only"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  338306284
                                ),
                                model: {
                                  value: _vm.object.debug,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "debug", $$v)
                                  },
                                  expression: "object.debug",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: {
                                  label: "Allow Latecomers Auto Assign",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Enable auto assing for in-progress games"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4199482329
                                ),
                                model: {
                                  value: _vm.object.allowLatecomersAutoAssign,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "allowLatecomersAutoAssign",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "object.allowLatecomersAutoAssign",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Hide Hosts" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " Make hosts invisible for players "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2975324292
                                ),
                                model: {
                                  value: _vm.object.anonymousUsers,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "anonymousUsers", $$v)
                                  },
                                  expression: "object.anonymousUsers",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Hide Auditors" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " Make auditors invisible for players "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  792904180
                                ),
                                model: {
                                  value: _vm.object.hideAuditors,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "hideAuditors", $$v)
                                  },
                                  expression: "object.hideAuditors",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Prompted Chat" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Players are automatically put into the video chat in the lobby when they arrive "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2120849572
                                ),
                                model: {
                                  value: _vm.object.autoChat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "autoChat", $$v)
                                  },
                                  expression: "object.autoChat",
                                },
                              }),
                              _vm.object.autoChat
                                ? _c("rtb-checkbox", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      label: "Prompted Chat - Group by team",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  " Return players to lobby in video groups of their team "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2887350304
                                    ),
                                    model: {
                                      value: _vm.object.autoChatByTeam,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "autoChatByTeam",
                                          $$v
                                        )
                                      },
                                      expression: "object.autoChatByTeam",
                                    },
                                  })
                                : _vm._e(),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Disable Portal Content" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " Disable automatically important content from Portal if any exists "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2543283554
                                ),
                                model: {
                                  value: _vm.object.disablePortalContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "disablePortalContent",
                                      $$v
                                    )
                                  },
                                  expression: "object.disablePortalContent",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Disable Lipdub" },
                                model: {
                                  value: _vm.object.lipdubDisabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "lipdubDisabled", $$v)
                                  },
                                  expression: "object.lipdubDisabled",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Disable Smart Audio" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " Disable Smart Audio. (Only YouTube) "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3330046434
                                ),
                                model: {
                                  value: _vm.object.disableSmartAudio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "disableSmartAudio",
                                      $$v
                                    )
                                  },
                                  expression: "object.disableSmartAudio",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Disable Tips" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " This will prevent the tips animation from showing after the game "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  597072230
                                ),
                                model: {
                                  value: _vm.object.tipsDisabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "tipsDisabled", $$v)
                                  },
                                  expression: "object.tipsDisabled",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Enabled Lobby Chat" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " This will turn off/on lobby chat "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3906302759
                                ),
                                model: {
                                  value: _vm.object.lobbyChatEnabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "lobbyChatEnabled",
                                      $$v
                                    )
                                  },
                                  expression: "object.lobbyChatEnabled",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Auto advance Pseudo Games" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " If enabled, game rounds will auto advance when all games in the round ends "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3225661693
                                ),
                                model: {
                                  value: _vm.object.pseodoGamesAutoAdvance,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "pseodoGamesAutoAdvance",
                                      $$v
                                    )
                                  },
                                  expression: "object.pseodoGamesAutoAdvance",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Allow Skipping Onboarding" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " Allow Skipping Onboarding "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1494823956
                                ),
                                model: {
                                  value: _vm.object.skippableOnboarding,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "skippableOnboarding",
                                      $$v
                                    )
                                  },
                                  expression: "object.skippableOnboarding",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: {
                                  label: "Disable Lobby Locked Chat Pop Up",
                                },
                                model: {
                                  value: _vm.object.disableLiveChatFtux,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "disableLiveChatFtux",
                                      $$v
                                    )
                                  },
                                  expression: "object.disableLiveChatFtux",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Demo Event" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " Marks the event as a demo Event "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4151366600
                                ),
                                model: {
                                  value: _vm.object.demo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "demo", $$v)
                                  },
                                  expression: "object.demo",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: {
                                  label: "Presenter + Mobile (Depricated)",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              ' Marks the event as a "Presenter + Mobile" event (Depricated) '
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3338209248
                                ),
                                model: {
                                  value: _vm.object.mobileHybrid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "mobileHybrid", $$v)
                                  },
                                  expression: "object.mobileHybrid",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Freeze Scribe" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " This would allow for an initial scribe assignment, but then NEVER advance the scribe throughout the game "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1125965399
                                ),
                                model: {
                                  value: _vm.object.staticScribeAssignment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "staticScribeAssignment",
                                      $$v
                                    )
                                  },
                                  expression: "object.staticScribeAssignment",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "rtb-card-actions",
                        [
                          _c(
                            "rtb-button",
                            { on: { click: _vm.update } },
                            [
                              _vm.loading
                                ? _c("rtb-spinner", {
                                    attrs: { color: "currentColor" },
                                  })
                                : _c("svg-icon", {
                                    attrs: { name: "save-regular" },
                                  }),
                              _vm._v(" Save "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "attendance", attrs: { title: "Attendance" } },
            [
              _vm.tab === 3
                ? _c("ClientSettingsAttendance", {
                    staticStyle: { "background-color": "#fff" },
                    attrs: { clientID: _vm.clientID, client: _vm.client },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "style", attrs: { title: "Styles" } },
            [
              _c("ClientSettingsStylesTabpanel", {
                attrs: { client: _vm.object },
                on: {
                  "input:styles": _vm.onStylesInput,
                  "input:theme": _vm.onThemeInput,
                  save: _vm.update,
                },
              }),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "images", attrs: { title: "Images" } },
            [
              _c(
                "rtb-card",
                [
                  _c(
                    "rtb-card-body",
                    [
                      _c(
                        "rtb-row",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: { label: "Top Left Image (old)" },
                                model: {
                                  value: _vm.object.logoImage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "logoImage", $$v)
                                  },
                                  expression: "object.logoImage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: {
                                  label:
                                    "Custom Welcome Banner (Featured overrides)",
                                },
                                model: {
                                  value: _vm.object.welcomeImage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "welcomeImage", $$v)
                                  },
                                  expression: "object.welcomeImage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: { label: "Featured Lobby Image" },
                                model: {
                                  value: _vm.object.newLobbyCustomLogo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "newLobbyCustomLogo",
                                      $$v
                                    )
                                  },
                                  expression: "object.newLobbyCustomLogo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: { label: "Collapsed Toolbar Logo" },
                                model: {
                                  value: _vm.object.customLogo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "customLogo", $$v)
                                  },
                                  expression: "object.customLogo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: { label: "Expanded Toolbar Logo" },
                                model: {
                                  value: _vm.object.customLogoLarge,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "customLogoLarge", $$v)
                                  },
                                  expression: "object.customLogoLarge",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: { label: "Login Page Logo" },
                                model: {
                                  value: _vm.object.loginLogo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "loginLogo", $$v)
                                  },
                                  expression: "object.loginLogo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: { label: "Game Background" },
                                model: {
                                  value: _vm.object.gameBackground,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "gameBackground", $$v)
                                  },
                                  expression: "object.gameBackground",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: {
                                  label: "Video Announcement Top Image",
                                },
                                model: {
                                  value: _vm.object.announcementTopImage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "announcementTopImage",
                                      $$v
                                    )
                                  },
                                  expression: "object.announcementTopImage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: {
                                  label: "Video Announcement Bottom Image",
                                },
                                model: {
                                  value: _vm.object.announcementBottomImage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "announcementBottomImage",
                                      $$v
                                    )
                                  },
                                  expression: "object.announcementBottomImage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("image-uploader-next", {
                                attrs: { label: "Pick Teams Image" },
                                model: {
                                  value: _vm.object.globalPickTeams,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "globalPickTeams", $$v)
                                  },
                                  expression: "object.globalPickTeams",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._l(_vm.clientImages, function (image, index) {
                            return _c(
                              "rtb-col",
                              {
                                key: "upload-image-" + index,
                                attrs: { xs: "2" },
                              },
                              [
                                _c("image-uploader-next", {
                                  attrs: {
                                    label: "Carousel Image " + (index + 1),
                                  },
                                  model: {
                                    value: _vm.clientImages[index],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.clientImages, index, $$v)
                                    },
                                    expression: "clientImages[index]",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "rtb-card-actions",
                        [
                          _c(
                            "rtb-button",
                            { on: { click: _vm.update } },
                            [
                              _vm.loading
                                ? _c("rtb-spinner", {
                                    attrs: { color: "currentColor" },
                                  })
                                : _c("svg-icon", {
                                    attrs: { name: "save-regular" },
                                  }),
                              _vm._v(" Save "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "login", attrs: { title: "Login" } },
            [
              _c(
                "rtb-card",
                [
                  _c(
                    "rtb-card-body",
                    [
                      _c(
                        "rtb-row",
                        [
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("rtb-text-input", {
                                attrs: {
                                  label: "Tagline",
                                  rules: _vm.getRules().ClientTagline,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "The text that appears right at the top of the login page under the logo"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  910409235
                                ),
                                model: {
                                  value: _vm.object.tagline,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "tagline", $$v)
                                  },
                                  expression: "object.tagline",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("rtb-text-input", {
                                attrs: { label: "Enter Login Info Text" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "The text that appears above the first and last name fields on login"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3783115687
                                ),
                                model: {
                                  value: _vm.object.enterLoginText,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "enterLoginText", $$v)
                                  },
                                  expression: "object.enterLoginText",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "2" } },
                            [
                              _c("rtb-select", {
                                attrs: {
                                  options: _vm.customInputTypes,
                                  identity: "value",
                                  label: "Custom Input",
                                  "option-text": "label",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "When you want to add an additional field next to the first and last name"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1855941707
                                ),
                                model: {
                                  value: _vm.object.customInputType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "customInputType", $$v)
                                  },
                                  expression: "object.customInputType",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.hasCustomInputLabelSetting
                            ? _c(
                                "rtb-col",
                                { attrs: { xs: "2" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Custom Input Label" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v("Placeholder"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1389206948
                                    ),
                                    model: {
                                      value: _vm.object.customInputLabel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "customInputLabel",
                                          $$v
                                        )
                                      },
                                      expression: "object.customInputLabel",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.hasCustomInputOptions
                            ? _c(
                                "rtb-col",
                                { attrs: { xs: "2" } },
                                [
                                  _c("rtb-textarea", {
                                    attrs: {
                                      label: "Custom Input Options",
                                      placeholder: "Comma Separated Options",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v("Placeholder"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1389206948
                                    ),
                                    model: {
                                      value: _vm.object.customInputOptions,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.object,
                                          "customInputOptions",
                                          $$v
                                        )
                                      },
                                      expression: "object.customInputOptions",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.object.customInputType === "password" ||
                          _vm.object.password
                            ? _c("rtb-col", { attrs: { xs: "2" } }, [
                                _c(
                                  "form",
                                  { attrs: { autocomplete: "off" } },
                                  [
                                    _c("rtb-text-input", {
                                      attrs: {
                                        label: "Optional Password",
                                        type: "password",
                                        autocomplete: "off",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "help",
                                            fn: function () {
                                              return [
                                                _c("rtb-inline-help", [
                                                  _vm._v(
                                                    " A password to gain access to the game "
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1855510795
                                      ),
                                      model: {
                                        value: _vm.object.password,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.object, "password", $$v)
                                        },
                                        expression: "object.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "3" } },
                            [
                              _c("rtb-text-input", {
                                attrs: { label: "Login Background Color" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Replaces the purple color on login"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1439570896
                                ),
                                model: {
                                  value: _vm.object.customLoginColor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "customLoginColor",
                                      $$v
                                    )
                                  },
                                  expression: "object.customLoginColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "my-3" },
                        [
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: { label: "Email Login" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v("Placeholder"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1389206948
                            ),
                            model: {
                              value: _vm.object.regularLogin,
                              callback: function ($$v) {
                                _vm.$set(_vm.object, "regularLogin", $$v)
                              },
                              expression: "object.regularLogin",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: { label: "Live Chat Premium" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          "If checked, The chat will change from robot to a human/live chat"
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1320319340
                            ),
                            model: {
                              value: _vm.packageLevel,
                              callback: function ($$v) {
                                _vm.packageLevel = $$v
                              },
                              expression: "packageLevel",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: { label: "Use Avatar" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v("Placeholder"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1389206948
                            ),
                            model: {
                              value: _vm.object.noUserPhoto,
                              callback: function ($$v) {
                                _vm.$set(_vm.object, "noUserPhoto", $$v)
                              },
                              expression: "object.noUserPhoto",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: {
                              label: "Show photos without opacity layer",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          " Login page photos don't have the opacity off when this is checked "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1085102276
                            ),
                            model: {
                              value: _vm.object.carouselOpacityOff,
                              callback: function ($$v) {
                                _vm.$set(_vm.object, "carouselOpacityOff", $$v)
                              },
                              expression: "object.carouselOpacityOff",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: {
                              label: "Require CVS Email Match to Login",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          " For added security, when using CSV Email Match, the user has to be on the list to gain access. "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1547169541
                            ),
                            model: {
                              value: _vm.object.csvEmailMatchRequired,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.object,
                                  "csvEmailMatchRequired",
                                  $$v
                                )
                              },
                              expression: "object.csvEmailMatchRequired",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: { label: "Conference Login Option" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          " Players are given the option of being a conference room "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2152481590
                            ),
                            model: {
                              value: _vm.object.conferenceLogin,
                              callback: function ($$v) {
                                _vm.$set(_vm.object, "conferenceLogin", $$v)
                              },
                              expression: "object.conferenceLogin",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: { label: "Remove Login Images Backgrounds" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          " This will remove the backgound image in the login page "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              615482517
                            ),
                            model: {
                              value: _vm.object.removeLoginImagesBackgrounds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.object,
                                  "removeLoginImagesBackgrounds",
                                  $$v
                                )
                              },
                              expression: "object.removeLoginImagesBackgrounds",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: {
                              disabled: !_vm.object.showChooseGameCard,
                              label: "Game Selector (UNAVAILABLE)",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          " On Onboarding a 4th card displays offering a game selector "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1651113131
                            ),
                            model: {
                              value: _vm.object.showChooseGameCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.object, "showChooseGameCard", $$v)
                              },
                              expression: "object.showChooseGameCard",
                            },
                          }),
                          _vm.object.showChooseGameCard
                            ? _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: {
                                  disabled: !_vm.object.showChooseTeam,
                                  label: "Team Selector (UNAVAILABLE)",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " On Onboarding a 4th card displays offering a team selector "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2910806840
                                ),
                                model: {
                                  value: _vm.object.showChooseTeam,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "showChooseTeam", $$v)
                                  },
                                  expression: "object.showChooseTeam",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-card-actions",
                    [
                      _c(
                        "rtb-button",
                        { on: { click: _vm.update } },
                        [
                          _vm.loading
                            ? _c("rtb-spinner", {
                                attrs: { color: "currentColor" },
                              })
                            : _c("svg-icon", {
                                attrs: { name: "save-regular" },
                              }),
                          _vm._v(" Save "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isLobby
            ? _c(
                "rtb-tab",
                { key: "emails", attrs: { title: "Match Emails" } },
                [
                  _c("ClientSettingMatchEmails", {
                    attrs: {
                      clientID: _vm.clientID,
                      client: _vm.client,
                      orgID: _vm.orgID,
                      games: _vm.gamesArrayForMatching,
                      saving: _vm.loading,
                    },
                    on: { update: _vm.update, fetchGame: _vm.fetchGame },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "rtb-tab",
            { key: "actions", attrs: { title: "Actions" } },
            [
              _c("ClientSettingActionsTabpanel", {
                attrs: { clientID: _vm.clientID },
              }),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "escape-room", attrs: { title: "Escape Room" } },
            [
              _c(
                "rtb-card",
                { attrs: { "fill-height": "" } },
                [
                  _c(
                    "rtb-container",
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Show User Scores" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(" Show user scores "),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2124388334
                                ),
                                model: {
                                  value: _vm.object.userscoreEnabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.object,
                                      "userscoreEnabled",
                                      $$v
                                    )
                                  },
                                  expression: "object.userscoreEnabled",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Escape Room Template" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Define as Escape Room Template "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4143152318
                                ),
                                model: {
                                  value: _vm.object.escapeRoom,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "escapeRoom", $$v)
                                  },
                                  expression: "object.escapeRoom",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            {
                              staticStyle: { "margin-top": "30px" },
                              attrs: { xs: "3", "mt-3": "" },
                            },
                            [
                              _c("rtb-text-input", {
                                attrs: { label: "Intro Video" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "YouTube code for intro video. If set, video will be played before players enter the lobby."
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3080647669
                                ),
                                model: {
                                  value: _vm.object.lobbyIntroVideo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "lobbyIntroVideo", $$v)
                                  },
                                  expression: "object.lobbyIntroVideo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            {
                              staticStyle: { "margin-top": "30px" },
                              attrs: { xs: "3", "mt-3": "" },
                            },
                            [
                              _c(
                                "rtb-button",
                                {
                                  attrs: { size: "sm" },
                                  on: { click: _vm.auditGames },
                                },
                                [_vm._v("Audit Games")]
                              ),
                            ],
                            1
                          ),
                          false
                            ? _c(
                                "rtb-col",
                                {
                                  staticStyle: { "margin-top": "30px" },
                                  attrs: { xs: "3", "mt-3": "" },
                                },
                                [
                                  _c(
                                    "rtb-button",
                                    {
                                      attrs: { color: "danger", size: "sm" },
                                      on: { click: _vm.resetAllGames },
                                    },
                                    [_vm._v("Reset All Games")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "rtb-card-actions",
                        [
                          _c(
                            "rtb-button",
                            { on: { click: _vm.update } },
                            [
                              _vm.loading
                                ? _c("rtb-spinner", {
                                    attrs: { color: "currentColor" },
                                  })
                                : _c("svg-icon", {
                                    attrs: { name: "save-regular" },
                                  }),
                              _vm._v(" Save "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isSuper
            ? _c(
                "rtb-tab",
                { key: "music", attrs: { title: "Music" } },
                [_c("rtb-card", [_c("ClientAudio")], 1)],
                1
              )
            : _vm._e(),
          _c(
            "rtb-tab",
            { key: "teams", attrs: { title: "Teams" } },
            [
              _c(
                "rtb-card",
                [
                  _vm.canLoadClientTeam
                    ? _c("ClientGames", { attrs: { games: _vm.filtered } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "referral", attrs: { title: "Ads" } },
            [
              _c("SessionSettingsReferralPanel", {
                on: {
                  save: function ($event) {
                    return _vm.update()
                  },
                },
                model: {
                  value: _vm.object,
                  callback: function ($$v) {
                    _vm.object = $$v
                  },
                  expression: "object",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }