<template>
  <v-container column style="max-height: 80vh; overflow: auto">
    <DynamicScroller :items="gamesComputed" :min-item-size="50">
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.teams]"
          :data-index="index"
        >
          <div>
            <v-layout class="headline"> {{ item.name }} </v-layout>
            <GameTeams
              :game="item"
              :playthroughs="playthroughs"
              @update="fetchPlayThroughs"
              @teamUpdate="onTeamUpdate"
            />
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </v-container>
</template>

<script>
import { db } from "@/firebase"
import { mapGetters } from "vuex"
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
import { fetchGameTeamsObject } from "@/services/game.service"
import GameTeams from "./GameTeams.vue"
import Collection from "@shared/Collection"
import Team from "@shared/Team"

export default {
  name: "ClientSettingTeamEdit",
  components: {
    GameTeams,
    DynamicScroller,
    DynamicScrollerItem
  },
  props: ["games"],
  data() {
    return {
      gamesComputed: [],
      playthroughs: []
    }
  },
  computed: {
    ...mapGetters("auth", ["clientID"]),
    ...mapGetters(["orgID"])
  },
  async created() {
    this.fetchPlayThroughs()
    const fetchTeams = async game => {
      const teams = await fetchGameTeamsObject({
        gameID: game.id,
        orgID: this.orgID
      })
      game.teams = Team.normalize(teams || {})
      return game
    }

    this.gamesComputed = (
      await Promise.all(this.games.map(game => fetchTeams(game)))
    ).sort((a, b) => {
      return b?.teams?.length - a?.teams?.length
    })
  },
  methods: {
    async fetchPlayThroughs() {
      const snap = await db
        .auxiliary()
        .ref(`client/${this.clientID}/playthrough`)
        .once("value")
      this.playthroughs = Collection.normalize(snap.val() || {})
    },
    onTeamUpdate({ gameID, update }) {
      const updatedGameIndex = this.gamesComputed.findIndex(
        game => game.id === gameID
      )
      if (updatedGameIndex >= 0) {
        const updatedTeamIndex = this.gamesComputed[
          updatedGameIndex
        ]?.teams.findIndex(team => team.id === update.id)
        if (updatedTeamIndex >= 0) {
          let updatedTeams = this.gamesComputed[updatedGameIndex].teams
          updatedTeams[updatedTeamIndex] = update
          this.$set(this.gamesComputed, updatedGameIndex, {
            ...this.gamesComputed[updatedGameIndex],
            teams: [...updatedTeams]
          })
        }
      }
      console.log(gameID, update, this.gamesComputed)
    }
  }
}
</script>
