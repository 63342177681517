
























import Vue from "vue"

import { RecycleScroller } from "vue-virtual-scroller"

import HostsProvider from "@/providers/hosts.provider"

import InputButton from "@/components/ui/InputButton/InputButton.vue"
import {
  RtbRow,
  RtbCol,
  RtbCard,
  RtbSelect,
  RtbContainer
} from "@/components/ui"

import SessionSettingsTemplateSelectGroup from "./SessonSettings/SessionSettingsTemplateSelectGroup.vue"
import ClientSettingGame from "./ClientSettingGame.vue"
import _ from "lodash"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"

export default Vue.extend({
  name: "ClientSettingsGames",
  data() {
    return {
      selectedMasterGameID: undefined,
      selectedUserGameID: undefined
    }
  },
  model: {
    prop: "selected",
    event: "input"
  },
  props: {
    selected: Object,
    games: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HostsProvider,
    InputButton,
    RtbRow,
    RtbCol,
    RtbSelect,
    RtbCard,
    RtbContainer,
    ClientSettingGame,
    RecycleScroller,
    SessionSettingsTemplateSelectGroup
  },
  computed: {
    _selected: {
      get() {
        return this.selected
      },
      set(value) {
        this.$emit("selected", value)
      }
    }
  },
  methods: {
    add({ n, template }) {
      this.working = true
      this.selectedMasterGameID = template.id
      this.$emit("add", {
        template,
        count: n
      })
    }
  }
})
