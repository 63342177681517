































import Vue from "vue"
import invarinat from "invariant"
import type { WithRefs } from "vue-typed-refs"

import { AudioCollection, AudioEntry } from "@/modules/audio/types"

import AudioList from "@/components/GroupTeams/Common/AudioList.vue"
import AudioSettings from "@/components/GroupTeams/Common/AudioSettings.vue"

export type Refs = {
  editor: InstanceType<typeof AudioSettings>
}

export default (Vue as WithRefs<Refs>).extend({
  name: "ClientAudio",
  components: {
    AudioSettings,
    AudioList
  },
  data() {
    return {
      audios: [] as AudioEntry[],
      collection: AudioCollection.Client,
      audio: null,
      modal: false
    }
  },
  created() {
    this._unsubscribe = null
    this.subscribeToAudios()
  },
  beforeDestroy() {
    this.unsubscribeFromAudios()
  },
  computed: {
    clientID(): string {
      return this.$store.state.auth.clientID
    }
  },
  watch: {
    modal(value: boolean) {
      if (value === false) {
        this.$refs.editor.reset()
        this.audio = null
      }
    }
  },
  methods: {
    initEdit(audioID: string) {
      this.audio = this._getAudioByID(audioID)
      this.modal = true
    },
    initCreation() {
      this.modal = true
      this.$refs.editor.init()
    },
    subscribeToAudios() {
      return this.$services.get("audio").then(service => {
        this._unsubscribe = service.subscribe(
          AudioCollection.Client,
          {
            clientID: this.clientID
          },
          audios => {
            this.audios = audios
          }
        )
      })
    },
    unsubscribeFromAudios() {
      if (this._unsubscribe) {
        this._unsubscribe()
        this._unsubscribe = null
      }
    },
    setAudio(audioID: string) {
      const audio = this._getAudioByID(audioID)
      this.audio = audio
      const { player } = this.$refs.player

      player.source = {
        type: "audio",
        title: audio.name,
        sources: [
          {
            src: audio.source,
            type: "audio/mp3"
          }
        ]
      }

      player.volume = audio.volume / 100
      player.play()
    },
    _getAudioByID(audioID: string) {
      const audio = this.audios.find(audio => audio.id === audioID)
      invarinat(audio, `Audio with id "${audioID}" not found`)
      return audio
    }
  }
})
