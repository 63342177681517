var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "pl-2 pt-2 client-settings-actions-tab-panel" },
    [
      _c(
        "rtb-row",
        [
          _c("rtb-col", { attrs: { xs: "12" } }, [
            _c("h1", [_vm._v("USE WITH CAUTION 💣")]),
          ]),
        ],
        1
      ),
      _c(
        "rtb-row",
        [
          _c(
            "rtb-col",
            { attrs: { xs: "12" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "rtb-button",
                            _vm._g(
                              {
                                attrs: {
                                  color: "danger",
                                  disabled: _vm.loading,
                                },
                                on: { click: _vm.toggleLobby },
                              },
                              on
                            ),
                            [
                              _vm._v(
                                " LOBBY " +
                                  _vm._s(_vm.hasLobby ? "ON" : "OFF") +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Toggle Lobby Page")])]
              ),
            ],
            1
          ),
          _c(
            "rtb-col",
            { attrs: { xs: "12" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "rtb-button",
                            _vm._g(
                              {
                                attrs: {
                                  color: "danger",
                                  disabled: _vm.loading,
                                },
                                on: { click: _vm.purge },
                              },
                              on
                            ),
                            [_vm._v(" PURGE ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Clear auto-assignment")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "rtb-row",
        [
          _c(
            "rtb-col",
            { attrs: { xs: "12" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "rtb-button",
                            _vm._g(
                              {
                                attrs: {
                                  color: "danger",
                                  disabled: _vm.loading,
                                },
                                on: { click: _vm.obliviate },
                              },
                              on
                            ),
                            [_vm._v(" OBLIVIATE ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", [
                    _vm._v("Players will forget the games that they played"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "rtb-row",
        [
          _c(
            "rtb-col",
            { attrs: { xs: "12" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "rtb-button",
                            _vm._g(
                              {
                                staticClass: "btn",
                                attrs: {
                                  color: "danger",
                                  disabled: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearChat("lobby")
                                  },
                                },
                              },
                              on
                            ),
                            [_vm._v(" CLEAR LOBBY CHAT ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Clears all lobby chat messages")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "rtb-button",
                            _vm._g(
                              {
                                staticClass: "btn",
                                attrs: {
                                  color: "danger",
                                  disabled: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearChat("lobby-host")
                                  },
                                },
                              },
                              on
                            ),
                            [_vm._v(" CLEAR LOBBY-HOST CHAT ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Clears all host chat messages in lobby")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "rtb-button",
                            _vm._g(
                              {
                                staticClass: "btn",
                                attrs: {
                                  color: "danger",
                                  disabled: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearChat("rooms")
                                  },
                                },
                              },
                              on
                            ),
                            [_vm._v(" CLEAR GAME CHAT ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Clears all chat messages in all rooms")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "rtb-row",
        [
          _c(
            "rtb-col",
            { attrs: { xs: "12" } },
            [
              _c(
                "rtb-button",
                {
                  staticClass: "btn",
                  attrs: { color: "accent", disabled: _vm.loading },
                  on: { click: _vm.exportToJSON },
                },
                [_vm._v(" Export to JSON ")]
              ),
              _c(
                "rtb-button",
                {
                  staticClass: "btn",
                  attrs: { color: "accent", disabled: _vm.loading },
                  on: { click: _vm.importFromJSON },
                },
                [_vm._v(" Import from JSON ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "rtb-row",
        [
          _c(
            "rtb-col",
            { attrs: { xs: "12" } },
            [
              _c("rtb-spinner", {
                style: { opacity: _vm.loading ? "1" : "0" },
              }),
              _vm._t("actions", null, { loading: _vm.loading }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }