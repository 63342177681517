var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { display: "flex", "align-items": "end" } }, [
    _c(
      "div",
      { staticClass: "mr-3" },
      [
        _c("rtb-select", {
          attrs: {
            options: _vm.nOfCopiesOptions,
            label: "Number of Copies",
            disabled: _vm.disabled,
          },
          model: {
            value: _vm.nOfCopies,
            callback: function ($$v) {
              _vm.nOfCopies = $$v
            },
            expression: "nOfCopies",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mr-3", staticStyle: { flex: "1" }, attrs: { xs: "3" } },
      [
        _c("rtb-select", {
          attrs: {
            options: _vm.templateTypeOptions,
            label: "Template Type",
            disabled: _vm.disabled,
          },
          model: {
            value: _vm.templateType,
            callback: function ($$v) {
              _vm.templateType = $$v
            },
            expression: "templateType",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mr-3", staticStyle: { flex: "2" } },
      [
        _c("rtb-select", {
          attrs: {
            options: _vm.templates,
            identity: "id",
            label: "Templates",
            "option-text": "name",
            disabled: _vm.disabled,
          },
          model: {
            value: _vm.templateId,
            callback: function ($$v) {
              _vm.templateId = $$v
            },
            expression: "templateId",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "padding-bottom": "2px" } },
      [
        _c(
          "rtb-button",
          {
            attrs: { disabled: _vm.template == null || _vm.disabled },
            on: { click: _vm.add },
          },
          [_vm._v(" Add ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }