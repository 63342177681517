var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "tournament-user-manager", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _vm.available.length
            ? _c(
                "v-flex",
                [
                  _c("v-select", {
                    attrs: {
                      slot: "header",
                      items: _vm.available,
                      label: "ADD USER",
                      "hide-details": "",
                      dense: "",
                      "item-text": "name",
                      "item-value": "id",
                    },
                    on: { change: _vm.addUsers },
                    slot: "header",
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _vm._l(
                    _vm.usersGroupedByPriority,
                    function (users, priority) {
                      return _c(
                        "v-flex",
                        {
                          key: "tournament-priority-group-" + priority,
                          attrs: { "d-flex": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-center",
                                  attrs: { shrink: "" },
                                },
                                [
                                  _vm._v(
                                    " PRIORITY LEVEL " + _vm._s(priority) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-flex",
                                { attrs: { "d-flex": "" } },
                                [
                                  _c(
                                    "draggable",
                                    _vm._b(
                                      {
                                        staticClass: "layout column",
                                        attrs: {
                                          list: users,
                                          disabled: _vm.drag,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onChange(
                                              $event,
                                              priority
                                            )
                                          },
                                        },
                                      },
                                      "draggable",
                                      { group: "people", animation: 150 },
                                      false
                                    ),
                                    _vm._l(users, function (user) {
                                      return _c(
                                        "v-flex",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                user.id.indexOf("TEMP") < 0,
                                              expression:
                                                "user.id.indexOf('TEMP') < 0",
                                            },
                                          ],
                                          key: "tournament-user-" + user.id,
                                          staticClass:
                                            "tournament-user-manager__user",
                                          attrs: {
                                            shrink: "",
                                            "data-uuid": user.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tournament-user-manager__user__name",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(user.firstname) +
                                                  " " +
                                                  _vm._s(user.lastname) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "tournament-user-manager__user__delete-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteUser(user.id)
                                                },
                                              },
                                            },
                                            [_vm._v("delete")]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "", "d-flex": "", "align-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { circle: "", small: "", fab: "" },
                          on: { click: _vm.addPriorityLevel },
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { "mt-2": "", "text-xs-center": "" } },
            [
              _vm.changed
                ? _c(
                    "v-btn",
                    {
                      staticClass: "success",
                      attrs: {
                        flat: "",
                        disabled: false,
                        loading: _vm.working,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" SUBMIT ")]
                  )
                : _vm._e(),
              _vm.changed
                ? _c(
                    "v-btn",
                    {
                      staticClass: "warning",
                      attrs: { flat: "", disabled: _vm.working },
                      on: { click: _vm.discard },
                    },
                    [_vm._v(" DISCARD ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }