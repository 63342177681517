var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "tournament-controls", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("v-flex", { attrs: { shrink: "", "text-xs-center": "" } }, [
            _c("h2", [_vm._v("Tournament Controls")]),
          ]),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c("rtb-select", {
                attrs: {
                  options: _vm.tournamentIDs,
                  label: "Select Tournament",
                },
                model: {
                  value: _vm.currentTournamentID,
                  callback: function ($$v) {
                    _vm.currentTournamentID = $$v
                  },
                  expression: "currentTournamentID",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              !!_vm.next
                ? _c(
                    "v-layout",
                    { attrs: { "mb-3": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            shrink: "",
                            "d-flex": "",
                            "align-center": "",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "success",
                              attrs: {
                                flat: "",
                                disabled:
                                  !_vm.isRoundTime ||
                                  _vm.isStarted(_vm.next.id),
                                loading: _vm.working,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.nextRound()
                                },
                              },
                            },
                            [
                              !_vm.isRoundTime &&
                              !!_vm.current &&
                              !!_vm.current.expectedEndTime
                                ? [
                                    _vm._v(" NEXT ROUND IN "),
                                    _c("PreGameCountdown", {
                                      staticClass:
                                        "tournament-controls__next-button__countdow",
                                      attrs: {
                                        endTime: _vm.current.expectedEndTime,
                                      },
                                    }),
                                  ]
                                : _vm.isStarted(_vm.next.id)
                                ? [_vm._v(" STARTED ")]
                                : [_vm._v(" NEXT ROUND ")],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      !!_vm.next && _vm.isStarted(_vm.next.id)
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                shrink: "",
                                "d-flex": "",
                                "align-center": "",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "warning",
                                  attrs: { flat: "", loading: _vm.working },
                                  on: { click: _vm.resetRound },
                                },
                                [_vm._v(" RESET ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isStarted(_vm.next.id)
                        ? [
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  shrink: "",
                                  "d-flex": "",
                                  "align-center": "",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "warning",
                                    attrs: { flat: "", loading: _vm.working },
                                    on: { click: _vm.forceNextRound },
                                  },
                                  [_vm._v(" FORCE NEXT ROUND ")]
                                ),
                              ],
                              1
                            ),
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "d-flex": "",
                                    "align-center": "",
                                    shrink: "",
                                    "pr-2": "",
                                  },
                                },
                                [_vm._v(" Truncate To Top ")]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass:
                                    "tournament-controls__number-input",
                                  attrs: {
                                    "d-flex": "",
                                    "align-center": "",
                                    shrink: "",
                                    "mr-2": "",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "#",
                                      loading: _vm.working,
                                      disabled: _vm.loserTolerant,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.truncateTo,
                                      callback: function ($$v) {
                                        _vm.truncateTo = _vm._n($$v)
                                      },
                                      expression: "truncateTo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            !_vm.isStarted(_vm.next.id)
                              ? [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                        shrink: "",
                                        "pr-2": "",
                                      },
                                    },
                                    [_vm._v(" Teams Per Game ")]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "tournament-controls__number-input",
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                        shrink: "",
                                        "mr-2": "",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          loading: _vm.working,
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.teamsPerGame,
                                          callback: function ($$v) {
                                            _vm.teamsPerGame = _vm._n($$v)
                                          },
                                          expression: "teamsPerGame",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  "d-flex": "",
                                  "align-center": "",
                                  shrink: "",
                                },
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    label: "All Teams Advance",
                                    disabled: !!_vm.truncateTo,
                                    readonly: _vm.working,
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.loserTolerant,
                                    callback: function ($$v) {
                                      _vm.loserTolerant = $$v
                                    },
                                    expression: "loserTolerant",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          !!_vm.tournament
            ? [
                _c(
                  "v-flex",
                  { attrs: { "d-flex": "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        !!_vm.tournament.games
                          ? _vm._l(_vm.sortedGamesMerged, function (game) {
                              return _c(
                                "v-flex",
                                {
                                  key: "tournament-game-" + game.id,
                                  attrs: { "d-flex": "" },
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { column: "" } },
                                    [
                                      _c("v-flex", { attrs: { shrink: "" } }, [
                                        _vm._v(
                                          " Game ID: " + _vm._s(game.id) + " "
                                        ),
                                        game.id === _vm.currentGameID
                                          ? _c("b", [_vm._v(" (Current)")])
                                          : game.id === _vm.nextGameID
                                          ? _c("b", [_vm._v("(Next)")])
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          !!game.timestamp
                                            ? [
                                                _vm._v(" Starts In "),
                                                _c("PreGameCountdown", {
                                                  attrs: {
                                                    endTime: game.timestamp,
                                                  },
                                                }),
                                              ]
                                            : [_vm._v(" Unavailable ")],
                                        ],
                                        2
                                      ),
                                      !!game.teams
                                        ? _vm._l(
                                            game.teams,
                                            function (team, teamID) {
                                              return _c(
                                                "v-flex",
                                                {
                                                  key:
                                                    "tournament-game-" +
                                                    game.id +
                                                    "-team-" +
                                                    teamID,
                                                  class: {
                                                    "tournament-controls__team-pending":
                                                      !!team.pending,
                                                    "tournament-controls__team-winner":
                                                      _vm.isWinner(
                                                        game.id,
                                                        teamID,
                                                        team.pending
                                                      ),
                                                  },
                                                  attrs: {
                                                    "d-flex": "",
                                                    "align-center": "",
                                                    "pa-2": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "tournament-controls__team-name",
                                                          attrs: {
                                                            "d-flex": "",
                                                            "align-center": "",
                                                            xs5: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                team.name ||
                                                                  "PENDING..."
                                                              ) +
                                                              " "
                                                          ),
                                                          !!team.pending
                                                            ? _c("b", [
                                                                _vm._v(
                                                                  "(IN PROGRESS)"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "tournament-controls__online",
                                                          attrs: {
                                                            "ml-2": "",
                                                            xs4: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              class: {
                                                                "low-online":
                                                                  game.id ==
                                                                  _vm.currentGameID
                                                                    ? team.online <
                                                                      _vm.MIN_USERS_ONLINE
                                                                    : false,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Online: " +
                                                                  _vm._s(
                                                                    team.online
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("div", [
                                                            _vm._v(
                                                              "Offline: " +
                                                                _vm._s(
                                                                  team.offline
                                                                )
                                                            ),
                                                          ]),
                                                          _c("div", [
                                                            _vm._v(
                                                              "Total: " +
                                                                _vm._s(
                                                                  team.total
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs3: "",
                                                            "ml-2": "",
                                                            "d-flex": "",
                                                            "align-center": "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Score: " +
                                                              _vm._s(
                                                                team.score
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs3: "",
                                                            "ml-2": "",
                                                            "d-flex": "",
                                                            "align-center": "",
                                                          },
                                                        },
                                                        [
                                                          game.id ==
                                                            _vm.currentGameID &&
                                                          team.total > 0 &&
                                                          !team.pending
                                                            ? _c("v-select", {
                                                                attrs: {
                                                                  loading:
                                                                    _vm.merging,
                                                                  disabled:
                                                                    _vm.merging,
                                                                  items:
                                                                    _vm.mergeOptions(
                                                                      teamID,
                                                                      game.id
                                                                    ),
                                                                  label:
                                                                    "MERGE TO",
                                                                  "hide-details":
                                                                    "",
                                                                  dense: "",
                                                                  "item-text":
                                                                    "name",
                                                                  "item-value":
                                                                    "id",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      value
                                                                    ) {
                                                                      return _vm.onSelectChange(
                                                                        {
                                                                          team1:
                                                                            {
                                                                              id: value,
                                                                              name: game
                                                                                .teams[
                                                                                value
                                                                              ]
                                                                                .name,
                                                                            },
                                                                          team2:
                                                                            {
                                                                              id: teamID,
                                                                              name: team.name,
                                                                            },
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          game.id ==
                                                            _vm.currentGameID &&
                                                          team.total > 0 &&
                                                          !team.pending
                                                            ? _c("rtb-select", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.mergeOptions(
                                                                      teamID,
                                                                      game.id
                                                                    ),
                                                                  disabled:
                                                                    _vm.merging,
                                                                  label:
                                                                    "MERGE TO",
                                                                  identity:
                                                                    "id",
                                                                  "option-text":
                                                                    "name",
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      value
                                                                    ) {
                                                                      return _vm.onSelectChange(
                                                                        {
                                                                          team1:
                                                                            {
                                                                              id: value,
                                                                              name: game
                                                                                .teams[
                                                                                value
                                                                              ]
                                                                                .name,
                                                                            },
                                                                          team2:
                                                                            {
                                                                              id: teamID,
                                                                              name: team.name,
                                                                            },
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    game.id,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        game,
                                                                        "id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "game.id",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          game.id ==
                                                            _vm.currentGameID &&
                                                          team.pending
                                                            ? _c("rtb-select", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.sendOptions(
                                                                      team.gameID,
                                                                      game.id
                                                                    ),
                                                                  disabled:
                                                                    _vm.merging,
                                                                  label:
                                                                    "SEND TEAM TO",
                                                                  identity:
                                                                    "id",
                                                                  "option-text":
                                                                    "name",
                                                                },
                                                                on: {
                                                                  "search:focus":
                                                                    _vm.fetchClientGames,
                                                                  input:
                                                                    function (
                                                                      value
                                                                    ) {
                                                                      return _vm.onSendSelectChange(
                                                                        {
                                                                          gameID:
                                                                            value,
                                                                          teamID:
                                                                            teamID,
                                                                          fromGameID:
                                                                            team.gameID,
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      game.id ==
                                                      _vm.currentGameID
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs1: "",
                                                                "mr-2": "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "error",
                                                                  attrs: {
                                                                    icon: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onTeamDelete(
                                                                          teamID,
                                                                          team.gameID,
                                                                          team.name
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "delete"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          !!_vm.tournament
            ? [
                _c("v-flex", { attrs: { shrink: "", "text-xs-center": "" } }, [
                  _c("h2", [_vm._v("User Manager")]),
                ]),
                _c(
                  "v-flex",
                  { attrs: { "d-flex": "" } },
                  [
                    _c("TournamentHostManager", {
                      attrs: {
                        tournamentID: _vm.currentTournamentID,
                        tournament: _vm.tournament,
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }