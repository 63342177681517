<template>
  <rtb-card>
    <RtbCardBody>
      <RtbRow>
        <RtbCol xs="3">
          <RtbDatepicker
            :disabled="disabled"
            v-model="timestamp"
            label="Trigger Date"
            timestamp
          />
        </RtbCol>
        <RtbCol xs="3">
          <RtbTimepicker
            :disabled="disabled"
            v-model="timestamp"
            label="Trigger Time"
            timestamp
          />
        </RtbCol>
      </RtbRow>
      <RtbRow class="mb-1">
        <RtbCol>
          <RtbTextInput
            v-model="url"
            label="Banner Media URL"
            :disabled="disabled"
            :placeholder="defaultBannerMediaUrl"
          >
            <template #help>
              <RtbInlineHelp>
                {{ `${defaultBannerMediaUrl} - by default` }}
              </RtbInlineHelp>
            </template>
          </RtbTextInput>
        </RtbCol>
      </RtbRow>
      <RtbRow class="mb-4">
        <RtbCol>
          <rtb-checkbox v-model="disabled" label="Disabled" />
        </RtbCol>
      </RtbRow>
      <RtbCardActions>
        <RtbButton @click="save">
          <svg-icon name="save-regular" /> Save
        </RtbButton>
      </RtbCardActions>
    </RtbCardBody>
  </rtb-card>
</template>

<script>
import Vue from "vue"
import {
  RtbRow,
  RtbCol,
  RtbCard,
  RtbCardBody,
  RtbCheckbox,
  RtbInlineHelp,
  RtbTextInput,
  RtbButton,
  RtbDatepicker,
  RtbTimepicker,
  RtbCardActions
} from "@/components/ui"
import { v4 as uuidv4 } from "uuid"
import useSystem from "@/use/useSystem"

export default Vue.extend({
  name: "SessionSettingsReferralPanel",
  model: {
    prop: "value",
    event: "change"
  },
  components: {
    RtbCard,
    RtbTextInput,
    RtbRow,
    RtbCardBody,
    RtbCol,
    RtbCheckbox,
    RtbInlineHelp,
    RtbButton,
    RtbDatepicker,
    RtbTimepicker,
    RtbCardActions
  },
  props: {
    value: Object
  },
  setup() {
    const { bannerMediaUrl: defaultBannerMediaUrl } = useSystem()
    return { defaultBannerMediaUrl }
  },
  methods: {
    save() {
      this.$emit("save")
    }
  },
  computed: {
    disabled: {
      get() {
        return this.value?.referral?.disabled
      },
      set(value) {
        const data = { ...this.value }
        data.referral = { ...data.referral, disabled: value, id: uuidv4() }
        this.$emit("change", data)
      }
    },
    url: {
      get() {
        return this.value?.referral?.url
      },
      set(value) {
        const data = { ...this.value }
        data.referral = { ...data.referral, url: value, id: uuidv4() }
        this.$emit("change", data)
      }
    },
    timestamp: {
      get() {
        return (
          this.value.referral?.timestamp ||
          this.value.startTimestamp + 45 * 1000 * 60
        )
      },
      set(value) {
        const data = { ...this.value }
        data.referral = { ...data.referral, timestamp: value, id: uuidv4() }
        this.$emit("change", data)
      }
    }
  }
})
</script>
