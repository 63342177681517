var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-plyr", { ref: "player" }, [
        _c("audio", [_c("source", { attrs: { type: "audio/mp3" } })]),
      ]),
      _c("AudioList", {
        attrs: { title: "Session Music", items: _vm.audios },
        on: {
          create: _vm.initCreation,
          edit: _vm.initEdit,
          select: function ($event) {
            return _vm.setAudio($event)
          },
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "512px" },
          model: {
            value: _vm.modal,
            callback: function ($$v) {
              _vm.modal = $$v
            },
            expression: "modal",
          },
        },
        [
          _c("AudioSettings", {
            ref: "editor",
            attrs: { collection: _vm.collection, value: _vm.audio },
            on: {
              save: function ($event) {
                _vm.modal = false
              },
              delete: function ($event) {
                _vm.modal = false
              },
              copy: function ($event) {
                _vm.modal = false
              },
              cancel: function ($event) {
                _vm.modal = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }