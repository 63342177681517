<template>
  <fieldset v-if="ready">
    <legend class="sr-only">Tournament {{ tournament.id }} fields</legend>
    <!-- Players -->

    <v-container fluid class="tournament-container">
      <div class="tournament-stats">
        <v-layout class="tournament-stats-inner">
          <div class="rtb-d-flex">
            <v-text-field
              v-model.number="localTournament.playersCount"
              label="Players count"
              box
              :disabled="!isBeingEdited || saved"
              hide-details
              class="rtb-max-w-16"
            />
            <v-text-field
              v-model.number="localTournament.playersPerTeam"
              label="Players per team"
              box
              :disabled="!isBeingEdited || saved"
              hide-details
              class="rtb-max-w-16"
            />
          </div>
        </v-layout>
      </div>
      <v-layout row wrap>
        <!-- Rounds -->

        <v-flex
          v-for="(game, index) in localTournamentSortedGames"
          :key="`tournament-round-${game.id}`"
          xs12
          sm4
          md3
          xl2
          class="tournament-round"
        >
          <div class="rtb-d-flex">
            <v-layout column class="tournament-column">
              <v-layout row v-if="!!game">
                <v-flex>
                  {{ getStartDate(game) }}
                </v-flex>
              </v-layout>
              <v-layout
                row
                class="tournament-header"
                :style="isBeingEdited ? null : 'pointer-events:none;'"
              >
                <v-flex d-flex align-center class="round-number"
                  >{{ isRoundOver(game) ? "✅" : "" }} Round
                  {{ index + 1 }}</v-flex
                >
                <v-flex d-flex class="tournament-header__time-picker-outline">
                  <TournamentDateTimePicker
                    type="start"
                    :game="game"
                    :isBeingEdited="isBeingEdited"
                    @onSetDate="setGameDate($event, game, 'start')"
                    @onSetHours="setGameHours($event, game, 'start')"
                    @onSetMinutes="setGameMinutes($event, game, 'start')"
                    @onSetOffset="setOffset($event, game, 'start')"
                  />
                </v-flex>
                <v-flex d-flex class="tournament-header__time-picker-outline">
                  <TournamentDateTimePicker
                    type="end"
                    :game="game"
                    :isBeingEdited="isBeingEdited"
                    @onSetDate="setGameDate($event, game, 'end')"
                    @onSetHours="setGameHours($event, game, 'end')"
                    @onSetMinutes="setGameMinutes($event, game, 'end')"
                    @onSetOffset="setOffset($event, game, 'end')"
                  />
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs8 d-flex align-center>
                  <v-text-field
                    v-model="game.label"
                    box
                    label="Label"
                    :disabled="!isBeingEdited"
                    hide-details
                    class="tournament-label"
                  />
                </v-flex>
                <v-flex xs4 d-flex ml-1 align-center>
                  <v-text-field
                    v-model.number="game.teamsPerGame"
                    box
                    label="Teams"
                    :disabled="!isBeingEdited"
                    hide-details
                    class="tournament-n-of-teams"
                  />
                </v-flex>
              </v-layout>
              <rtb-select
                v-model="game.id"
                :options="getSelectGames(game.id)"
                :disabled="!isBeingEdited || isRoundOver(game)"
                label="Game"
                identity="id"
                option-text="name"
              />
              <div class="round-info">
                <div class="round-info-inner">
                  <div>Games ≈ {{ getGamesCount(game) }}</div>
                  <div>Teams ≈ {{ approximation[index].numberOfTeams }}</div>
                </div>
              </div>
              <div
                v-if="isBeingEdited && !isRoundOver(game)"
                class="tournament-container__add-game-btn-container"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      circle
                      small
                      fab
                      v-on="on"
                      @click="addGameToRound(game.id)"
                      class="tournament-container__add-game-btn"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                  <span>+1 GAME</span>
                </v-tooltip>
              </div>
            </v-layout>
          </div>
        </v-flex>

        <!-- Controls -->

        <v-flex d-flex shrink align-center>
          <v-btn v-if="!isBeingEdited" fab small depressed @click="onEdit"
            ><v-icon>edit</v-icon></v-btn
          >
          <template v-else>
            <v-btn fab color="green" small depressed dark @click="onAddGame"
              ><v-icon>add</v-icon></v-btn
            >
            <v-btn
              v-if="localTournament.canDeleteGame()"
              fab
              color="red"
              dark
              small
              depressed
              @click="onDeleteGame"
              ><v-icon>delete</v-icon></v-btn
            >
            <v-btn
              v-if="hasChanged && canSubmit"
              color="primary"
              depressed
              @click="onSubmit"
              >Submit</v-btn
            >
            <!-- <v-btn v-if="hasChanged" color="yellow" depressed @click="onDiscard"
              >Discard</v-btn
            > -->
            <v-btn color="red" dark depressed @click="onDelete">Delete</v-btn>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
  </fieldset>
</template>

<script>
import Vue from "vue"
import { isEqual } from "lodash"
import moment from "moment"

// eslint-disable-next-line no-unused-vars
import { Tournament } from "./entities/Tournament"
import { TournamentGame } from "./entities/TournamentGame"
import { TournamentGameStatus } from "./entities/TournamentGameStatus"
import { RoundManager } from "./entities/RoundManager"
import { RtbSelect } from "@/components/ui"

import { MODULE_NAME, ACTION_TYPES } from "@/store/TournamentModule"
import { Event } from "./event"

import { Firebase } from "@/helpers"
import {
  approximateTournament,
  DEFAULT_NUMBER_OF_TEAMS_PER_GAME
} from "@/helpers/tournament"
import TournamentDateTimePicker from "./TournamentDateTimePicker"

export default Vue.extend({
  name: "TournamentBuilderTournament",
  components: { TournamentDateTimePicker, RtbSelect },
  props: {
    tournament: {
      /** @type { import("vue").PropType<Tournament> } */
      type: Object,
      required: true
    },
    games: {
      /** @type { import("vue").PropType<{ id: string }[]> } */
      type: Array,
      required: true
    },
    saved: {
      type: Boolean
    }
  },
  data() {
    return {
      isBeingEdited: false,
      /** We want to modify it locally, instead of mutation the passed tournament */
      /** @type {Tournament} */
      localTournament: null,
      /** @type {Record<string, RoundManager>}  */
      roundManagers: {},
      ready: false,
      shoudlApllyOffsetToAllRounds: null
    }
  },
  computed: {
    localTournamentSortedGames() {
      return this.localTournament.getSortedGames()
    },
    /**
     * @returns {TournamentGame[]}
     */
    localTournamentGames() {
      return this.localTournament ? this.localTournament.games || [] : []
    },
    /**
     * @returns {boolean}
     */
    hasChanged() {
      return this.localTournament === null
        ? false
        : isEqual(this.tournament, this.localTournament) === false
    },
    /**
     * @returns {boolean}
     */
    canSubmit() {
      return this.localTournament && this.localTournament.getGames().length
    },
    // TODO: type
    /**
     * @returns {any}
     */
    approximation() {
      const { localTournament: tournament } = this
      if (tournament) {
        const { playersCount, playersPerTeam } = tournament
        const games = tournament.getGames()
        return approximateTournament(
          games.length,
          playersCount,
          playersPerTeam,
          games.map(({ teamsPerGame }) => teamsPerGame)
        )
      } else {
        return null
      }
    }
  },
  watch: {
    tournament: {
      /**
       * @param {Tournament} value
       */
      handler(value) {
        this.localTournament = value.clone()
        this.getRoundGames()
      },
      immediate: true
    }
  },
  created() {
    /* We don't want it to be reactive */
    this.$bus.$on(Event.CREATE_TOURNAMENT, this.onCreateTournament)
  },
  beforeDestroy() {
    this.$bus.$off(Event.CREATE_TOURNAMENT, this.onCreateTournament)
  },
  methods: {
    getStartDate(game) {
      return moment(game.getTimestamp("start")).format("MM/DD/YYYY")
    },
    addGameToRound(gameID) {
      const index = this.localTournamentGames.findIndex(
        game => game.id === gameID
      )
      const game = this.localTournamentGames[index]
      if (!game) return console.error("Generic error")
      this.$emit("increment", {
        tournamentID: this.localTournament.id,
        roundID: game.id,
        timestamp: game.timestamp,
        round: index + 1,
        label: game.label,
        teamsPerGame: game.teamsPerGame
      })
    },
    onEdit() {
      this.isBeingEdited = true
    },
    onAddGame() {
      const { localTournament: tournament } = this
      // get the last one game
      const game = tournament.getGames()[tournament.getGames().length - 1]

      // if last game exists, then create a new one with timestamp + 1 hour + 5 min (break time)
      const break_time = 5 * 60 * 1000
      let timestamp
      if (game) {
        // get expectedEndTime or timmestamp + 1 hour (for old games)
        timestamp = game.expectedEndTime
          ? game.expectedEndTime
          : game.timestamp + 60 * 60 * 1000
        timestamp += break_time
      } else {
        timestamp = Date.now()
      }
      const round = new TournamentGame({
        id: this.getFirstAvailableGameID(),
        timestamp,
        status: TournamentGameStatus.UPCOMING
      })

      tournament.addGame(round)

      this.$set(this.roundManagers, round.id, new RoundManager(round, []))
    },
    onDeleteGame() {
      this.localTournament.deleteGame()
    },
    onSubmit() {
      this.isBeingEdited = false
      this.$emit("input", this.localTournament)
    },
    /* onDiscard() {
      this.isBeingEdited = false
      this.localTournament = this.tournament.clone()
    }, */
    onDelete() {
      this.$emit("delete", this.tournament)
    },
    /**
     * @param {string} gameID
     * @returns { { id: string }[] }
     * @description Filter out the games that already selected, but leave the gameID
     */
    getSelectGames(gameID) {
      return this.games.filter(
        game =>
          game.id === gameID ||
          this.localTournament.getGame(game.id) === undefined
      )
    },
    /**
     * @description Restrict a game from being selected more than once
     */
    getAvailableGames() {
      return this.games.filter(
        game => this.localTournament.getGame(game.id) === undefined
      )
    },
    /**
     * @returns {string}
     */
    getFirstAvailableGameID() {
      const [game] = this.getAvailableGames()
      return game.id
    },
    /**
     * @param {TournamentGame} game
     * @returns {number}
     */
    getSortedGameIndex(game) {
      return this.localTournamentSortedGames.indexOf(game)
    },
    /**
     * @param {string} tournamentID
     */
    onCreateTournament(tournamentID) {
      if (this.tournament.id === tournamentID) {
        this.isBeingEdited = true
      }
    },
    setOffset(offset, game, type) {
      try {
        if (this.shoudlApllyOffsetToAllRounds === null) {
          this.shoudlApllyOffsetToAllRounds = confirm(
            `Would you like to add ${offset} minutes to the following round(s)?`
          )
        }

        if (this.shoudlApllyOffsetToAllRounds) {
          // TODO(Andrew): handle not found
          const index = this.getSortedGameIndex(game)
          const gamesToApplyOffsetTo =
            this.localTournamentSortedGames.slice(index)

          // TODO(Andrew): abstract it and move to the separate method
          gamesToApplyOffsetTo.forEach(g => {
            for (const t of ["end", "start"]) {
              if (g === game && type === "end" && t === "start") continue
              const timestamp = g.getTimestamp(t)
              timestamp.add(offset, "minutes").valueOf()
              g.validateTimestamp(timestamp)
              g.setTimestamp(timestamp, t)
            }
          })
        } else {
          const timestamp = game.getTimestamp(type)
          timestamp.add(offset, "minutes").valueOf()
          game.validateTimestamp(timestamp)
          game.setTimestamp(timestamp, type)
        }
      } catch (e) {
        alert(e.message)
      }
    },
    setGameDate(date, game, type) {
      try {
        game.setDate(date, type)
      } catch (e) {
        alert(e.message)
      }
    },
    setGameHours(hours, game, type) {
      try {
        game.setHours(hours, type)
      } catch (e) {
        alert(e.message)
      }
    },
    /**
     *
     * @param {string} minutes
     * @param {TournamentGame} game
     * @param {string} type
     */
    setGameMinutes(minutes, game, type) {
      console.log("minutes", minutes)
      try {
        game.setMinutes(minutes, type)
      } catch (e) {
        alert(e.message)
      }
    },
    async getRoundGames() {
      const games = this.localTournament.getSortedGames()

      const res = await Promise.all(
        games.map(game =>
          this.$store.dispatch(
            `${MODULE_NAME}/${ACTION_TYPES.GET_ROUND_GAMES}`,
            { tournamentID: this.tournament.id, roundID: game.id }
          )
        )
      )

      this.roundManagers = games.reduce((prev, curr, index) => {
        prev[curr.id] = new RoundManager(
          curr,
          Firebase.normalizeSnapshotToArray(res[index])
        )

        return prev
      }, {})

      this.ready = true
    },
    /**
     * @param {TournamentGame} game
     */
    isRoundOver(game) {
      return this.roundManagers?.[game.id]?.isOver()
    },
    /**
     * @param {TournamentGame} game
     */
    getGamesCount(game) {
      const approximatedNumberOfGames =
        this.approximation[this.localTournament.getGameIndex(game)]
          .numberOfGames
      const roundManager = this.roundManagers[game.id]
      if (this.saved && game.isStarted() && roundManager) {
        const difference = roundManager.gamesCount - approximatedNumberOfGames
        return `${roundManager.gamesCount} (${
          difference > 0 ? "+" : ""
        }${difference})`
      } else {
        return approximatedNumberOfGames
      }
    }
  }
})
</script>

<style lang="scss">
.tournament-builder {
  .v-dialog__container {
    pointer-events: none;
  }

  .tournament-container {
    padding: 20px 0;
    &__add-game-btn-container {
      text-align: center;
    }
    &__add-game-btn {
      width: 20px;
      height: 20px;
      i {
        font-size: 16px !important;
      }
    }
  }
  .tournament-stats {
    padding: 7px 5px 0 5px;
    margin: 0 5px !important;
    border-bottom: solid 1px #a0a0a0;

    .stats {
      padding-right: 10px;
      border-right: solid 1px #a0a0a0;
      line-height: 16px;
      margin-right: 10px;
      text-transform: uppercase;
      font-weight: bold;
      &:last-child {
        border: none;
      }
    }
  }

  .tournament-stats-inner {
    font-size: 16px;
    margin-bottom: -8px !important;
    transform: scale(0.75);
    transform-origin: center left;
  }

  .tournament-round {
    padding: 10px !important;

    .tournament-column {
      margin: 0 !important;
    }

    .tournament-header {
      &__time-picker-outline {
        border: 1px solid #fff;
        border-radius: 4px;
        margin: 3px;
        padding: 1px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }

      color: $color-white;
      background: $color-primary-accent;
      margin: 0 !important;

      .round-number {
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 5px;
      }

      .tournament-date-container {
        position: relative;
        // max-width: 72px;
        padding: 0;
        .calendar {
          position: absolute;
          left: 5px;
          top: 2px;
          width: 16px;
          height: 16px;
        }
        .tournament-date {
          position: relative;
          z-index: 1;

          input {
            margin: 0;
            padding: 0;
            color: $color-white;
            text-align: right;
            cursor: pointer;
            max-width: 65px;
            font-size: 20px;
            text-align: center;
          }
          .v-text-field__slot {
            justify-content: center;
          }
          & > .v-input__control > .v-input__slot {
            background-color: transparent;
            min-height: unset;
            padding: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }

    .primary--text {
      color: $color-secondary-accent !important;
      caret-color: $color-secondary-accent !important;
    }

    .tournament-n-of-teams,
    .tournament-label,
    .tournament-game {
      .v-label {
        top: 10px;
      }
      input {
        font-size: 14px;
        margin-top: 12px !important;
      }
      & > .v-input__control > .v-input__slot {
        min-height: unset;
        padding: 0 5px;
        &:before {
          border-width: thin 0 0 0;
        }
        &:after {
          border-width: thin 0 0 0;
        }
      }
    }

    .tournament-label {
      label {
        transform: translateY(4px) scale(0.75);
        &.v-label--active {
          transform: translateY(-8px) scale(0.75);
        }
      }
    }

    .tournament-game {
      .v-select__selections {
        padding-top: 14px;
        height: 44px;
        font-size: 14px;
        .v-select__selection {
          margin: 0;
          padding: 0;
        }
      }
      &.v-text-field.v-text-field--enclosed .v-input__append-inner {
        margin-top: 12px;
      }
      & > .v-input__control > .v-input__slot {
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }

  .round-info {
    // color: $color-white;
    // background: #a0a0a0;
    padding: 0 5px;
    .round-info-inner {
      font-size: 16px;
      transform: scale(0.75);
      transform-origin: center left;
      div {
        font-weight: bold;
        // border-right: solid 1px $color-white;
        padding-right: 10px;
        margin-right: 10px;
        display: inline-block;
        line-height: 14px;

        &:last-child {
          border: none;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
}
</style>
