





































































import Vue from "vue"
import api from "@/services/axios.service"
import { saveAs } from "file-saver"

import {
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbTable,
  RtbContainer
} from "@/components/ui"

const TABLE_HEADERS = [
  {
    text: "Firstname",
    value: "firstName",
    sortable: false
  },
  {
    text: "Lastname",
    value: "lastName",
    sortable: false
  },
  {
    text: "Email",
    value: "email",
    sortable: false
  },
  {
    text: "Game",
    value: "game",
    sortable: false
  },
  {
    text: "Team",
    value: "teamname",
    sortable: false
  },
  {
    text: "Score",
    value: "score",
    sortable: false
  },
  {
    text: "Start Time",
    value: "startTime",
    sortable: false
  },
  {
    text: "End Time",
    value: "endTime",
    sortable: false
  }
]

export default Vue.extend({
  name: "ClientSettingsAttendance",
  components: {
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbTable,
    RtbContainer
  },
  props: {
    clientID: String,
    client: Object
  },
  data() {
    return {
      loading: false,
      data: [],
      TABLE_HEADERS
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(startAfter = "") {
      try {
        this.loading = true
        let queryParams = "?format=json"
        queryParams += startAfter ? `&startAfter=${startAfter}` : ""

        this.data = await api({
          method: "get",
          url: `session/${this.clientID}/report${queryParams}`,
          headers: {
            "X-TZ-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone
          }
        })
      } finally {
        this.loading = false
      }
    },
    async exportCSV(type) {
      try {
        this.loading = true
        const res = await api({
          method: "get",
          url: `session/${this.clientID}/report?format=csv&type=${type}`,
          responseType: "blob",
          headers: {
            "Content-Type": "text/csv",
            "X-TZ-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone
          }
        })
        saveAs(res, this.getCSVName(type))
      } finally {
        this.loading = false
      }
    },
    getCSVName(type) {
      let name = `${this.client.name}`
      const date = this.client.startTimestamp
        ? new Date(this.client.startTimestamp)
        : new Date()
      name += " " + date.toISOString().split("T")[0]
      if (type === "detailed") name += " Detailed"
      return `${name}.csv`
    },
    async clearList() {
      try {
        this.loading = true

        await this.$confirm({
          message:
            "Are you sure you want to completely clear the attendance list?",
          buttonColor: "danger"
        })

        await api({
          method: "delete",
          url: `session/${this.clientID}/report`
        })

        this.data = []
      } finally {
        this.loading = false
      }
    }
  }
})
