var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#686868", padding: "0 16px" } },
    [
      _c("SessionSettingsTemplateSelectGroup", {
        attrs: { disabled: _vm.disabled },
        on: { add: _vm.add },
      }),
      _c("HostsProvider", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var hosts = ref.hosts
              return [
                _c("RecycleScroller", {
                  staticStyle: {
                    "margin-top": "20px",
                    height: "calc(80vh - 300px)",
                  },
                  attrs: {
                    items: _vm.games,
                    "item-size": 100,
                    "key-field": "id",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var item = ref.item
                          var index = ref.index
                          return [
                            _c("ClientSettingGame", {
                              attrs: {
                                game: item,
                                index: index + 1,
                                users: hosts,
                              },
                              on: {
                                delete: _vm.$listeners.delete,
                                update: _vm.$listeners["update"],
                              },
                              model: {
                                value: _vm._selected[item.id],
                                callback: function ($$v) {
                                  _vm.$set(_vm._selected, item.id, $$v)
                                },
                                expression: "_selected[item.id]",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }