






















import Vue, { PropType } from "vue"
import YouTube from "@/components/YouTube.vue"

import {
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbButton,
  RtbTextarea,
  RtbContainer
} from "@/components/ui"

export default Vue.extend({
  name: "MatchEmailsModal",
  components: {
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbButton,
    RtbTextarea,
    RtbContainer
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>
    }
  },
  data() {
    return {
      text: "",
      list: []
    }
  },
  methods: {
    setAsList(value: string) {
      this.list = value
        .split(/[\s,;]+/)
        .filter(item => item)
        .map(item => item.toLowerCase())
    },
    closeModal() {
      this.$emit("input", false)
    },
    addEMails() {
      this.$emit("addEmails", this.list)
      this.list = []
      this.$emit("input", false)
    }
  }
})
