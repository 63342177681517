var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", [
      _c("h3", [_vm._v("Colors:")]),
      _c(
        "ul",
        _vm._l(_vm.theme.styles, function (color, key) {
          return _c(
            "li",
            { key: key },
            [
              _c("code", [_vm._v(_vm._s(key))]),
              _vm._v(": "),
              _c("ThemeColorPreview", { attrs: { value: color } }),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c("section", [
      _c("h3", [_vm._v("Images:")]),
      _c(
        "ul",
        _vm._l(_vm.theme.images, function (value, key) {
          return _c(
            "li",
            { key: key },
            [
              _c("code", [_vm._v(_vm._s(key))]),
              _vm._v(": "),
              typeof value === "string"
                ? _c("img", {
                    attrs: { src: value, width: "24", height: "24" },
                  })
                : _vm._l(value, function (image) {
                    return _c("img", {
                      key: image,
                      attrs: { src: image, width: "24", height: "24" },
                    })
                  }),
            ],
            2
          )
        }),
        0
      ),
    ]),
    _c("section", [
      _c("h3", [_vm._v("Meta:")]),
      _c(
        "ul",
        _vm._l(_vm.theme.meta, function (value, key) {
          return _c("li", { key: key }, [
            _c("code", [_vm._v(_vm._s(key))]),
            _vm._v(": " + _vm._s(value) + " "),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }