







































































import Vue from "vue"
import { isNil, isEqual, cloneDeep } from "lodash"

import { MODULE_NAME as THEME_MODULE_NAME } from "@/modules/theme/store"
import ThemeModuleGetterTypes from "@/modules/theme/store/getter-types"

import ThemesProvider from "@/modules/theme/providers/themes.provider"

import ThemeCustomizerModal from "@/modules/theme/components/ThemeCustomizerModal.vue"
import ThemePreview from "@/modules/theme/components/ThemePreview.vue"
import StylesEditor from "@/modules/theme/components/StylesEditor.vue"
import ThemeSelect from "@/modules/theme/components/ThemeSelect.vue"
import TagSelect from "@/modules/theme/components/TagSelect.vue"

import { ThemeFactory } from "@/modules/theme/entities/ThemeFactory"

import { DEFAULT_THEME_STYLES } from "@/modules/theme/constants"

import {
  RtbRow,
  RtbCol,
  RtbCard,
  RtbCardBody,
  RtbTextInput,
  RtbCheckbox,
  RtbInlineHelp
} from "@/components/ui"

export default Vue.extend({
  name: "ClientSettingsStylesTabpanel",
  components: {
    ThemesProvider,
    RtbRow,
    RtbCol,
    RtbCard,
    RtbCheckbox,
    RtbInlineHelp,
    RtbCardBody,
    RtbTextInput,
    ThemeCustomizerModal,
    ThemePreview,
    StylesEditor,
    ThemeSelect,
    TagSelect
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isThemeCustomizerModalVisible: false,
      themeID: this.client.themeID ?? null,
      styles: { ...DEFAULT_THEME_STYLES, ...this.client.styles },
      themeToCreate: null,
      themesTag: undefined,
      customLogoStyle: null,
      localClient: {
        customLogoStyle: null,
        primaryColorAsGameCardBackground: null
      }
    }
  },
  watch: {
    client: {
      handler(client) {
        this.localClient = cloneDeep(client) || {}
      },
      immediate: true
    }
  },
  computed: {
    orgID() {
      return this.$store.getters.orgID
    },
    themes() {
      return this.$store.getters[
        `${THEME_MODULE_NAME}/${ThemeModuleGetterTypes.NORMALIZED_THEMES}`
      ]
    },
    theme() {
      return this.themes.find(theme => theme.id === this.themeID)
    },
    /** @returns {boolean} */
    canReset() {
      const { themeID, styles } = this.client
      return (
        isNil(themeID) === false ||
        isEqual(styles, DEFAULT_THEME_STYLES) === false
      )
    },
    /** @returns {boolean} */
    dirty() {
      const {
        themeID = null,
        styles,
        primaryColorAsGameCardBackground = false,
        customLogoStyle = ""
      } = this.client
      return (
        themeID !== this.themeID ||
        primaryColorAsGameCardBackground !==
          this.localClient.primaryColorAsGameCardBackground ||
        customLogoStyle !== this.localClient.customLogoStyle ||
        (isEqual(styles, this.styles) === false &&
          isEqual(this.styles, DEFAULT_THEME_STYLES) === false)
      )
    }
  },
  methods: {
    showThemeCustomizerModal() {
      this.isThemeCustomizerModalVisible = true
    },
    onThemeUpdate() {
      this.themeToCreate = null
    },
    createTheme() {
      this.themeToCreate = ThemeFactory.create()
      this.showThemeCustomizerModal()
    },
    save() {
      this.client.customLogoStyle = this.localClient.customLogoStyle || ""
      this.client.primaryColorAsGameCardBackground =
        this.localClient.primaryColorAsGameCardBackground || false
      this.$emit("input:styles", this.styles)
      if (this.themeID !== this.client.themeID && this.themeID !== undefined) {
        this.$emit("input:theme", { themeID: this.themeID })
      }
      this.$emit("save")
    },
    deleteClientTheme() {
      this.$emit("input:theme", { themeID: null })
      this.themeID = null
    },
    purgeGames() {
      this.$services
        .get("theme")
        .then(service =>
          service.purgeGames(this.orgID, this.$store.state.auth.clientID)
        )
    },
    reset() {
      this.$emit("input:styles", null)
      this.styles = { ...DEFAULT_THEME_STYLES }
      this.themeID = null
      this.$emit("save")
    }
  }
})
