var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.localGame
    ? _c("div", { staticClass: "px-3 py-2", style: _vm.wrapperStyle }, [
        _c(
          "div",
          { staticClass: "rtb-d-flex align-end" },
          [
            _c("rtb-checkbox", {
              attrs: { label: "" },
              model: {
                value: _vm._selected,
                callback: function ($$v) {
                  _vm._selected = $$v
                },
                expression: "_selected",
              },
            }),
            _c("div", { staticClass: "w-6 text-white" }, [
              _vm._v(" " + _vm._s(_vm.index) + " "),
            ]),
            _c(
              "rtb-row",
              { staticClass: "flex-grow" },
              [
                _c(
                  "rtb-col",
                  { attrs: { xs: "2" } },
                  [
                    _c("rtb-text-input", {
                      attrs: {
                        value: _vm.localGame.name,
                        label: "Name",
                        size: "sm",
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "rtb-col",
                  { attrs: { xs: "3" } },
                  [
                    _c("rtb-text-input", {
                      attrs: {
                        label: "External name",
                        size: "sm",
                        disabled: _vm.loading,
                        rules: _vm.getRules().GameExternalName,
                        lazy: "",
                      },
                      model: {
                        value: _vm.localGame.externalName,
                        callback: function ($$v) {
                          _vm.$set(_vm.localGame, "externalName", $$v)
                        },
                        expression: "localGame.externalName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "rtb-col",
                  { attrs: { xs: "1" } },
                  [
                    !_vm.localGame.tournamentID
                      ? _c("rtb-text-input", {
                          attrs: {
                            label: "Round",
                            size: "sm",
                            disabled: _vm.loading,
                            lazy: "",
                          },
                          model: {
                            value: _vm.localGame.round,
                            callback: function ($$v) {
                              _vm.$set(_vm.localGame, "round", $$v)
                            },
                            expression: "localGame.round",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "rtb-col",
                  { attrs: { xs: "1" } },
                  [
                    _c("rtb-text-input", {
                      attrs: {
                        label: "Duration",
                        size: "sm",
                        disabled: _vm.loading,
                        lazy: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "help",
                            fn: function () {
                              return [
                                _c("rtb-inline-help", [
                                  _vm._v("Duration in Minutes"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        868936355
                      ),
                      model: {
                        value: _vm.duration,
                        callback: function ($$v) {
                          _vm.duration = $$v
                        },
                        expression: "duration",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "rtb-col",
                  { attrs: { xs: "3" } },
                  [
                    _c("rtb-select", {
                      attrs: {
                        options: _vm.userList,
                        label: "Host",
                        identity: "id",
                        "option-text": "fullname",
                        size: "sm",
                        disabled: _vm.loading,
                        "append-to-body": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selected-option",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                item.image
                                  ? _c("img", {
                                      staticClass:
                                        "w-6 h-6 rounded-full mr-1 object-cover",
                                      attrs: {
                                        src: item.image,
                                        alt: item.fullname + " avatar",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "relative",
                                    staticStyle: { top: "2px" },
                                  },
                                  [_vm._v(_vm._s(item.fullname))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1075188030
                      ),
                      model: {
                        value: _vm.localGame.hostUserID,
                        callback: function ($$v) {
                          _vm.$set(_vm.localGame, "hostUserID", $$v)
                        },
                        expression: "localGame.hostUserID",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "rtb-col",
                  { attrs: { xs: "2" } },
                  [
                    _c("rtb-select", {
                      attrs: {
                        options: _vm.gameTypes,
                        label: "Game Type",
                        size: "sm",
                        disabled: _vm.loading,
                        "append-to-body": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "help",
                            fn: function () {
                              return [
                                _c("rtb-inline-help", [
                                  _vm._v(
                                    "This is super important and determines what type of game will show up in the Expo. Standard is the only one that will auto assign players. "
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1971067625
                      ),
                      model: {
                        value: _vm.localGame.gameType,
                        callback: function ($$v) {
                          _vm.$set(_vm.localGame, "gameType", $$v)
                        },
                        expression: "localGame.gameType",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-3" },
              [
                _c(
                  "rtb-button",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      variant: "icon",
                      color: "danger",
                      disabled: _vm.loading,
                      "aria-label": "Delete",
                    },
                    on: { click: _vm.maybeDelete },
                  },
                  [_c("svg-icon", { attrs: { name: "trash-regular" } })],
                  1
                ),
                _c(
                  "rtb-button",
                  {
                    attrs: {
                      color: "grey",
                      variant: "icon",
                      disabled: _vm.loading,
                      "aria-label": "Initialize to Game",
                    },
                    on: { click: _vm.initializeToGame },
                  },
                  [_c("svg-icon", { attrs: { name: "sign-in-regular" } })],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "rtb-d-flex items-center mt-2 pl-4" },
          [
            _c(
              "time",
              {
                staticClass: "text-white mr-2",
                attrs: { datetime: new Date(_vm.game.startTimestamp) },
              },
              [_vm._v(_vm._s(_vm.startTime))]
            ),
            _c("rtb-checkbox", {
              staticClass: "mr-2",
              attrs: { label: "Deactivate" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("rtb-inline-help", [
                          _vm._v(
                            "Deactive games can be flipped on automatically when capacity is reached"
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3806758364
              ),
              model: {
                value: _vm.localGame.deactivate,
                callback: function ($$v) {
                  _vm.$set(_vm.localGame, "deactivate", $$v)
                },
                expression: "localGame.deactivate",
              },
            }),
            _c("rtb-checkbox", {
              staticClass: "mr-2",
              attrs: { label: "Stage" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("rtb-inline-help", [
                          _vm._v("Staged games can't be auto assigned to"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1478885430
              ),
              model: {
                value: _vm.localGame.ondeck,
                callback: function ($$v) {
                  _vm.$set(_vm.localGame, "ondeck", $$v)
                },
                expression: "localGame.ondeck",
              },
            }),
            _c("rtb-checkbox", {
              staticClass: "mr-2",
              attrs: { label: "Auditable" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("rtb-inline-help", [
                          _vm._v("Is the game auditable"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3242848717
              ),
              model: {
                value: _vm.localGame.auditable,
                callback: function ($$v) {
                  _vm.$set(_vm.localGame, "auditable", $$v)
                },
                expression: "localGame.auditable",
              },
            }),
            _c("rtb-checkbox", {
              staticClass: "mr-2",
              attrs: { label: "Keynote", disabled: _vm.keynoteDisabled },
              scopedSlots: _vm._u(
                [
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("rtb-inline-help", [
                          _vm._v("Keynote makes the game appear at the top"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1728035766
              ),
              model: {
                value: _vm.localGame.defaultKeynote,
                callback: function ($$v) {
                  _vm.$set(_vm.localGame, "defaultKeynote", $$v)
                },
                expression: "localGame.defaultKeynote",
              },
            }),
            _vm._v(" Origin ID: " + _vm._s(_vm.localGame.originalGameID) + " "),
            _c(
              "rtb-link",
              {
                staticClass: "ml-auto mr-2",
                attrs: {
                  href:
                    "/gamelogin/" +
                    _vm.game.clientID +
                    _vm.game.theKey +
                    "?auth=0",
                  target: "_blank",
                },
              },
              [_vm._v(" Login ")]
            ),
            _c(
              "rtb-link",
              {
                attrs: {
                  href:
                    "/gamelogin/" +
                    _vm.game.clientID +
                    _vm.game.theKey +
                    "?audit=1&auth=0",
                  target: "_blank",
                },
              },
              [_vm._v(" Audit ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }