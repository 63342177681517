var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    [
      _c(
        "RtbCardBody",
        [
          _c(
            "RtbRow",
            [
              _c(
                "RtbCol",
                { attrs: { xs: "3" } },
                [
                  _c("RtbDatepicker", {
                    attrs: {
                      disabled: _vm.disabled,
                      label: "Trigger Date",
                      timestamp: "",
                    },
                    model: {
                      value: _vm.timestamp,
                      callback: function ($$v) {
                        _vm.timestamp = $$v
                      },
                      expression: "timestamp",
                    },
                  }),
                ],
                1
              ),
              _c(
                "RtbCol",
                { attrs: { xs: "3" } },
                [
                  _c("RtbTimepicker", {
                    attrs: {
                      disabled: _vm.disabled,
                      label: "Trigger Time",
                      timestamp: "",
                    },
                    model: {
                      value: _vm.timestamp,
                      callback: function ($$v) {
                        _vm.timestamp = $$v
                      },
                      expression: "timestamp",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "RtbRow",
            { staticClass: "mb-1" },
            [
              _c(
                "RtbCol",
                [
                  _c("RtbTextInput", {
                    attrs: {
                      label: "Banner Media URL",
                      disabled: _vm.disabled,
                      placeholder: _vm.defaultBannerMediaUrl,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "help",
                        fn: function () {
                          return [
                            _c("RtbInlineHelp", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.defaultBannerMediaUrl + " - by default"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "RtbRow",
            { staticClass: "mb-4" },
            [
              _c(
                "RtbCol",
                [
                  _c("rtb-checkbox", {
                    attrs: { label: "Disabled" },
                    model: {
                      value: _vm.disabled,
                      callback: function ($$v) {
                        _vm.disabled = $$v
                      },
                      expression: "disabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "RtbCardActions",
            [
              _c(
                "RtbButton",
                { on: { click: _vm.save } },
                [
                  _c("svg-icon", { attrs: { name: "save-regular" } }),
                  _vm._v(" Save "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }