


























































import { db } from "@/firebase"
import Vue, { PropType } from "vue"
import {
  fetchGameTeamsObject,
  updateGameWith,
  updateTeamWith
} from "@/services/game.service"

import { MailMatcher } from "@/services/mail.matching"
import { MailMatch } from "@/types/mail.matching"
import MatchEmailsModal from "@/components/Game/MatchEmailsModal.vue"
import MatchEmailsTable from "@/components/Game/MatchEmailsTable.vue"
import type { Client } from "@/types/client"
import type { Teams } from "@/types/team"
import { Team } from "@/helpers"

import _ from "lodash"

import {
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbCheckbox,
  RtbContainer,
  RtbButton,
  RtbSpinner,
  RtbSelect,
  RtbTextarea,
  RtbTable,
  RtbTextInput,
  RtbInlineHelp
} from "@/components/ui"

export default Vue.extend({
  name: "ClientSettingMatchEmails",
  components: {
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbCheckbox,
    RtbContainer,
    RtbTextarea,
    RtbTable,
    RtbTextInput,
    RtbSpinner,
    RtbSelect,
    RtbInlineHelp,
    MatchEmailsModal,
    MatchEmailsTable
  },
  props: {
    client: {
      type: Object as PropType<Client>
    },
    clientID: {
      type: String
    },
    orgID: {
      type: String
    },
    saving: {
      type: Boolean
    },
    games: {
      type: Array
    }
  },
  data() {
    return {
      search: "",
      matcher: null as MailMatcher,
      data: [] as MailMatch[],
      loading: false,
      pagination: {},
      teams: {},
      showModal: false
    }
  },
  methods: {
    onChangeGame(item) {
      item.teamID = null
    },
    getTeamsByGameID(gameID) {
      return this.teams[gameID] || []
    },
    async fetchTeams(gameID) {
      const teams: Teams = await fetchGameTeamsObject({
        orgID: this.orgID,
        gameID
      })
      if (teams) {
        this.teams[gameID] = Object.entries(teams)
          .filter(([id, item]) => id && item.show)
          .map(([id, item]) => ({ ...item, id }))
      }
    },
    async onSelectCSVFile($event) {
      try {
        this.loading = true
        const file = ($event.target.files || {})[0]
        if (!file) {
          return
        }
        const rawData = await this.matcher.importFromCSVFile(file)
        const requestedTeams = rawData.map(({ gameID }) => gameID)
        const needMoreTeams = _.uniq(requestedTeams).length - this.games.length
        if (needMoreTeams > 0) {
          throw new Error(
            `You need to add at least ${needMoreTeams} games more with type "Standard or Green Room"`
          )
        }
        const {
          mappedData: data,
          mappedGames,
          mappedTeams
        } = await this.matcher.mapData(
          rawData,
          this.games,
          this.teams,
          async (gameID, num) => {
            return this.createAdditionalTeam(gameID, num)
          }
        )
        // merge current data and imported data
        this.data.forEach(item => {
          if (!item.email) return
          data.push(item)
        })
        this.data = data
        // Save to DB
        await this.matcher.setAllMailMatches(data)
        const promises = []
        // Update Games
        Object.entries(mappedGames).forEach(([newName, theKey]) => {
          if (!theKey) return
          promises.push(
            updateGameWith(theKey, this.orgID, { externalName: newName })
          )
          // Update Teams
          const teams = mappedTeams[newName]
          if (!teams) return
          Object.entries(teams).forEach(([teamNewName, teamTheKey]) => {
            promises.push(
              updateTeamWith(theKey, this.orgID, teamTheKey, {
                name: teamNewName
              })
            )
          })
        })
        await Promise.all(promises)
        this.$emit("fetchGame")
        $event.target.value = ""
      } catch (e) {
        console.error(e)
        alert(e)
      } finally {
        this.loading = false
      }
    },
    /**
     * Used as callback
     * @param gameID
     * @param num
     */
    async createAdditionalTeam(gameID, num) {
      const newTeam = Team.getNewTeam(num)
      const ref = Team.getTeamsRef(db.auxiliary(), {
        orgID: this.orgID,
        gameID
      })
      const id = ref.push(newTeam).key
      if (!this.teams[gameID]) this.teams[gameID] = []
      this.teams[gameID].push({ ...newTeam, id })
      return id
    },
    addEmails(emails) {
      if (!Array.isArray(emails)) return
      emails.forEach(email => {
        if (!email) return
        this.data.push({ email })
      })
    },
    async onSave() {
      try {
        this.loading = true
        await this.matcher.setAllMailMatches(this.data)
        this.$emit("update")
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async onClear() {
      this.client.simpleForm = false
      this.data = []
      await this.matcher.setAllMailMatches(this.data)
    }
  },
  computed: {
    gameList() {
      return this.games.map(({ theKey, externalName, name }) => ({
        theKey,
        name: externalName || name
      }))
    }
  },
  watch: {
    clientID: {
      async handler(value: string) {
        this.matcher = new MailMatcher(value)
        this.data = await this.matcher.getAllMatch()
      },
      immediate: true
    },
    games: {
      async handler(value: Array<any>) {
        try {
          this.loading = true

          this.teams = {}
          const promises = []
          // Fetch team keys for mapping
          value.forEach(({ theKey }) => {
            promises.push(this.fetchTeams(theKey))
          })
          await Promise.all(promises)
        } finally {
          this.loading = false
        }
      },
      immediate: true
    }
  }
})
