var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "RtbCard",
    [
      _c(
        "RtbContainer",
        [
          _c(
            "RtbCardBody",
            [
              _c(
                "div",
                { staticClass: "rtb-d-flex align-center mb-3" },
                [
                  _c(
                    "RtbButton",
                    {
                      attrs: { color: "danger", disabled: _vm.loading },
                      on: { click: _vm.clearList },
                    },
                    [_vm._v(" Clear List ")]
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: { "nudge-width": 100, "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var onMenu = ref.on
                            return [
                              _c(
                                "RtbButton",
                                _vm._g(
                                  {
                                    staticClass: "ml-auto",
                                    attrs: { disabled: _vm.loading },
                                  },
                                  onMenu
                                ),
                                [_vm._v(" Export to CSV ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dark: "" } },
                        [
                          _c(
                            "v-list-tile",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.exportCSV("report")
                                },
                              },
                            },
                            [_c("v-list-tile-title", [_vm._v(" Report ")])],
                            1
                          ),
                          _c(
                            "v-list-tile",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.exportCSV("detailed")
                                },
                              },
                            },
                            [
                              _c("v-list-tile-title", [
                                _vm._v(" Detailed Report "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("rtb-table"),
              _c(
                "v-data-table",
                {
                  staticClass: "rtb-table",
                  attrs: {
                    headers: _vm.TABLE_HEADERS,
                    items: _vm.data,
                    loading: _vm.loading,
                    "item-key": "id",
                    "hide-default-footer": "",
                    "hide-actions": "",
                    dark: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("tr", [
                            _c("td", [_vm._v(_vm._s(item.firstName))]),
                            _c("td", [_vm._v(_vm._s(item.lastName))]),
                            _c(
                              "td",
                              [
                                item.email && item.email !== "none"
                                  ? [_vm._v(" " + _vm._s(item.email) + " ")]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("td", [_vm._v(_vm._s(item.game))]),
                            _c("td", [_vm._v(_vm._s(item.teamname))]),
                            _c("td", [_vm._v(_vm._s(item.score))]),
                            _c("td", [
                              _c(
                                "span",
                                { staticClass: "whitespace-no-wrap" },
                                [_vm._v(" " + _vm._s(item.startTime) + " ")]
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "span",
                                { staticClass: "whitespace-no-wrap" },
                                [_vm._v(" " + _vm._s(item.endTime) + " ")]
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("v-progress-linear", {
                    attrs: { color: "blue", indeterminate: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }